@media (max-width: 1400px) {
    .height-span-addon{
        height: 32.0px!important;
    }
}@media (min-width: 1401px) {
    .height-span-addon{
        height: 32.5px!important;
    }
}

span.input-group-addon.h-35.no-border-radius.bg-tableheader{
    border: 1px solid var(--warna-d-border)!important;
    height: 32.5px!important;
}

.col-sm-12.pl-0.pr-0.text-center.align-self-center{
    border: 1px solid var(--warna-d-border)!important;
    height: 32.5px!important;
}

.box-warning-esbnreg{
    height: 46px!important;
}

.css-1af808t-Slider{
    width: 100%!important;
}

.f-saturate{
    filter: saturate(8);
}.f-sepia{
    filter: sepia(100%);
}.f-invert{
    filter: invert(100%);
}

 input.holo,
 .holo > input[type='text'] {
    /* You can set width to whatever you like */
    /*width: 200px;*/
    /*font-family: "Roboto", "Droid Sans", sans-serif;*/
    /*font-size: 16px;*/
    margin: 0;
    padding: 8px 8px 6px 8px;
    position: relative;
    display: block;
    outline: none;
    border: none;
    background: bottom left linear-gradient(#a9a9a9, #a9a9a9) no-repeat, bottom center linear-gradient(#a9a9a9, #a9a9a9) repeat-x, bottom right linear-gradient(#a9a9a9, #a9a9a9) no-repeat!important;
    background-size: 1px 6px, 1px 1px, 1px 6px!important;
}
input.holo,
.holo > input[type='text']:hover, input.holo[type='text']:focus {
    background: bottom left linear-gradient(#0099cc, #0099cc) no-repeat, bottom center linear-gradient(#0099cc, #0099cc) repeat-x, bottom right linear-gradient(#0099cc, #0099cc) no-repeat!important;
    background-size: 1px 6px, 1px 1px, 1px 6px !important;
}

.j-content-right {
    justify-content: right!important;
}

#crumbs ul li a{
    width: 100%!important;
}

.col-sm-form-wizard {
    flex: 0 0 33%!important;
    max-width: 33%!important;
    width: 33%!important;
}

.step-tab.step-esbn{
    width: 780px;
    margin-left: auto;
    margin-right: auto;
}

.css-1o70pz1-multiValue {
    background-color: var(--warna-text-basic)!important;
}

.css-15fnzkc {
    color: var(--warna-dasar) !important;
}

.ag-buysell > .ag-cell-wrapper{
    align-self: center!important;
    place-content: center!important;
}

@media (min-width: 576px) {
    .col-sm-2-5 {
        flex: 0 0 20%!important;
        max-width: 20%!important;
    }
}

.col-sm-2-5 {
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}


.panel-group .panel-heading + .panel-collapse > .panel-body, .panel-group .panel-heading + .panel-collapse > .list-group {
    border-top: .5px #ffffff30 solid;
}

.panel {
    margin-bottom: 20px;
    background-color: #ffffff30;
    border: .5px #ffffff30 solid;
    border-radius: 4px;
}

.border-error{
    border: 1px #eb2720 solid!important;
}

#exTab .nav-pills > li > a {
    border-radius: 0 10px 0 0 ;
    color : white;
    background-color: #080808;
    margin-bottom : 2px;
    border: .5px #ffffff30 solid;
}

#exTab .tab-content {
    background-color: #080808;
    padding : 5px 15px;
    border: .5px #ffffff30 solid;
    border-radius: 0 10px 10px 10px ;
}

html, body {margin: 0; height: 100%; overflow: hidden}
.ui.about.modal>.header {
    background-image: linear-gradient(var(--warna-navy-odd), var(--warna-navy-even)) !important;
}
@media (max-width: 1224px){
    .w-logo {
        width: 80%;
        height: 80%;
    }
}
@media (min-width: 1225px) and (max-width: 1409px) {
    .w-logo {
        width: 100%;
        height: 100%;
    }
}
@media(min-width: 1410px) and (max-width: 1600px){
    .w-logo {
        width: 90%;
        height: 90%;
    }
}
@media (min-width: 1601px) and (max-width: 1700px) {
    .w-logo {
        width: 80%;
        height: 80%;
    }
}
@media (min-width: 1701px) and (max-width: 1800px) {
    .w-logo {
        width: 70%;
        height: 70%;
    }
}
@media (min-width: 1801px) and (max-width: 1900px){
    .w-logo {
        width: 60%;
        height: 60%;
    }
}
@media (min-width: 1901px){
    .w-logo {
        width: 55%;
        height: 55%;
    }
}

.h-auto{
    height: auto!important;
}

.pt-40{
    padding-top: 40px!important;
}

.card-290{
    height: var(--card-290);
}
.card-145{
    height: var(--card-145);
}

.v-align-items-center{
    display: flex;
    align-items: center;
}

.h-63{
    height: 63px!important;
}.h-67{
    height: 67px!important;
}

.v50-align-center{
    min-height: 50px;
    display: flex!important;
    align-items: center;
    justify-content: center;
}
.v15-align-center{
    min-height: 15px;
    display: flex!important;
    align-items: center;
    justify-content: center;
}

.bg-blackgrey{
    background: var(--warna-hover-menu) !important;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.text-bips-dark:active{
    color: var(--warna-btn-dark) !important;
    background: -webkit-linear-gradient(var(--warna-btn-dark-hover-disabled), var(--warna-btn-dark-hover-disabled)) !important;
    -webkit-background-clip: text!important;
    -webkit-text-fill-color: transparent!important;
}

.text-bips-dark-disabled{
    color: var(--warna-btn-dark) !important;
    background: -webkit-linear-gradient(var(--warna-btn-dark-hover-disabled), var(--warna-btn-dark-hover-disabled)) !important;
    -webkit-background-clip: text!important;
    -webkit-text-fill-color: transparent!important;
}

.text-disabled{
    color: var(--warna-pembatas) !important;
}

.click-disabled{
    cursor: not-allowed!important;
}

@media (min-width: 1160px) {
    .p-disconnect {
        padding-right: 25% !important;
        padding-left: 25% !important;
        padding-top: 40vh !important;
        /*padding-bottom: 10px !important;*/
        min-height: 100vh;
    }
}

@media (min-width: 1047px) and (max-width: 1159px) {
    .p-disconnect {
        padding-right: 20% !important;
        padding-left: 20% !important;
        padding-top: 37.5vh !important;
        /*padding-bottom: 100px !important;*/
        min-height: 100vh;
    }
}

@media (min-width: 992px) and (max-width: 1046px) {
    .p-disconnect {
        padding-right: 27.5% !important;
        padding-left: 27.5% !important;
        padding-top: 35vh !important;
        /*padding-bottom: 100px !important;*/
        min-height: 100vh;
    }
}

@media (max-width: 991px) {
    .p-disconnect {
        padding-right: 5% !important;
        padding-left: 5% !important;
        padding-top: 33vh !important;
        /*padding-bottom: 100px !important;*/
        min-height: 100vh;
    }
}



.card-326{
    height: 326px;
}

.ag-header-border-gray-live-trade > span > div > div > div > div > .ag-body-viewport.ag-layout-normal{
    overflow-y: hidden;
    -webkit-overflow-scrolling : unset;
}

.item-hover-active{
    color: var(--warna-black-white) !important;
    text-decoration: none!important;
    background-color: #00b3ee!important;
}

.text-right > .ag-cell-wrapper {
    justify-content: flex-end!important;
}.text-center > .ag-cell-wrapper {
    justify-content: center!important;
}

.text-form > label {
   color: #272727!important;
}

.text-form{
    color: #272727!important;
}

.bg-sell{
    background: #C4D69C!important;
}
.bg-buy{
    background: #FAA8AE!important;
}
.bg-amend{
    background: #B8DCE8!important;
}

.menuscaleheader{
    font-weight: 400;
    border: var(--warna-border-headermenu) solid 1px;
    align-items: center;
    text-decoration: none!important;
    position: relative;
    vertical-align: middle;
    line-height: 1;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    text-transform: none;
    border-radius: 5px!important;
    transition:background .1s ease, box-shadow .1s ease, color .1s ease ;
    box-shadow : 2px 2px var(--warna-shadow)!important;
    cursor: pointer!important;
    margin: 7px 3px;
    flex: 0 0 7%;
    max-width: 7%;
    color: var(--warna-text-header);
    background: linear-gradient(var(--warna-navy-headermenu-odd),var(--warna-navy-headermenu-even));
}

.menuscaleheader:hover{
    color: var(--warna-text-header);
    background: var(--warna-background-semantic);
}
.menuscaleheader:active{
    color: var(--warna-black-white);
    background: var(--warna-navy-headermenu-even);
}

.menuscaleheader.act{
    background-image: linear-gradient(#67AAD7,#0071BC) !important;
}

.col-sm-header-logo-scale {
    flex: 0 0 7%;
    max-width: 7%;

}

.col-sm-header-menu-scale {
    flex: 0 0 75%;
    max-width: 75%;

}

.col-sm-header-user-scale {
    flex: 0 0 19%;
    max-width: 19%;
}

.mb3{
    margin-bottom: 3.75px!important;
}

.d-border-gray-half{
    border: 1px solid var(--warna-gray)!important;
}

.card-467{
    height: var(--card-467) !important;
}.card-112{
    height: var(--card-112) !important;
}.card-362{
    height: var(--card-362) !important;
}.card-482{
    height: var(--card-482) !important;
}.card-283{
    height: var(--card-283) !important;
}.card-191{
    height: var(--card-191) !important;
}.card-323{
    height: var(--card-323) !important;
}.card-161{
    height: var(--card-161) !important;
}.card-161-1{
    height: var(--card-161-1) !important;
}.card-161-2{
    height: var(--card-161-2) !important;
}.card-346{
    height: var(--card-346) !important;
}.card-310{
    height: var(--card-310) !important;
}.card-176{
    height: var(--card-176) !important;
}.card-177{
    height: var(--card-177) !important;
}.card-234{
    height: var(--card-234) !important;
}.card-399{
    height: var(--card-399) !important;
}.card-344{
    height: var(--card-344) !important;
}.card-220{
    height: var(--card-220) !important;
}.card-514{
    height: var(--card-514) !important;
}.card-356{
    height: var(--card-356) !important;
}.card-132{
    height: var(--card-132) !important;
}.card-215{
    height: var(--card-215) !important;
}.card-152{
    height: var(--card-152) !important;
}.card-92{
    height: var(--card-92) !important;
}.card-76{
    height: var(--card-76) !important;
}

.bg-tableheader{
    background-color :rgb(51, 63, 80)!important;
    color : white!important;
}

.bg-white-grey-sell{
    background-image: linear-gradient(#DFDFDE,#F4F5F6);
    color: #5CB85C!important;
}

.bg-white-grey-buy{
    background-image: linear-gradient(#DFDFDE,#F4F5F6);
    color: #D9534F!important;
}

.d-bold{
    font-weight: bold!important;
}

.bg-data-orange{
    background: var(--warna-bg-data-orange);
}

.cssmenu > ul > li.active{
    background-image: linear-gradient(#67AAD7,#0071BC) !important;
    color: white!important;
}.cssmenumodal > ul > li.active{
     background-image: linear-gradient(#67AAD7,#0071BC) !important;
     color: white!important;
 }

.card-695{
    height: 695px;
}

.bg-navy-gradient-headermenu-odd{
    background-image: linear-gradient(var(--warna-navy-headermenu-even),var(--warna-navy-headermenu-odd));
}
.bg-navy-gradient-headermenu{
    background-image: linear-gradient(var(--warna-navy-headermenu-odd), var(--warna-navy-headermenu-even));
}

.main-header > a.active{
    color: white!important;
} .ui.menu.fluid > a.active{
      color: white!important;
  }

.text-jam{
    color : var(--warna-text-jam);
}

.h-72-77{
    height: 72.77px!important;
}.h-87{
     height: 87px!important;
 }

.ui.menu{
    height: 87px!important;
}

.bg-blue-white{
    background-image: linear-gradient(#67AAD7,#0071BC) !important;
    color: white!important;
}

.card-695{
    height: 695px;
}

.bg-navy-gradient-odd{
    background-image: linear-gradient(var(--warna-navy-even),var(--warna-navy-odd));
}
.bg-navy-gradient{
    background-image: linear-gradient(var(--warna-navy-odd), var(--warna-navy-even));
}
.bg-box-gradient{
    background-image: radial-gradient(var(--warna-bg-trading-gray), var(--warna-navy-odd), var(--warna-btn-dark-hover)) !important;
}

.table-sidebar > tbody > tr {
    /*background-image: linear-gradient(var(--warna-btn-dark-hover),var(--warna-bg-trading-gray));*/
    background-image: linear-gradient(var(--warna-black-white-gradient),var(--warna-black-white));
}.table-sidebar > tbody > tr:hover {
     background: var(--warna-hover-menu);
 }

.table-sidebar > thead > tr > th,
.table-sidebar > tbody > tr > th,
.table-sidebar > thead > tr > td,
.table-sidebar > tbody > tr > td,
.table-sidebar > tfoot > tr > td {
    align-self: center!important;
    vertical-align: middle!important;
    padding-bottom: 4px!important;
    padding-top: 4px!important;
}

.table-sidebar > tfoot > tr > th,
.table-sidebar > tfoot > tr > td {
    align-self: center!important;
    vertical-align: middle!important;
    padding-bottom: 3px!important;
    padding-top: 3px!important;
}

.d-border-gray{
    border: 1px solid var(--warna-gray)!important;
}

.d-border-left-half{
    border-left: 0.5px solid var(--warna-gray)!important;
}.d-border-right-half{
     border-right: 0.5px solid var(--warna-gray)!important;
 }

@media (min-width: 1160px) {
    .p-pinportofolio{
        padding-left: 27%;
        padding-right: 27%;
    }
    .p-pinlanding{
        padding-left: 35%;
        padding-right: 35%;
    }
}

@media (min-width: 1047px) and (max-width: 1159px) {
    .p-pinportofolio{
        padding-left: 24%;
        padding-right: 24%;
    }
    .p-pinlanding{
        padding-left: 32%;
        padding-right: 32%;
    }
}

@media (min-width: 992px) and (max-width: 1046px) {
    .p-pinportofolio{
        padding-left: 20%;
        padding-right: 20%;
    }
    .p-pinlanding{
        padding-left: 28%;
        padding-right: 28%;
    }
}

@media (max-width: 991px) {
    .p-pinportofolio{
        padding-left: 12%;
        padding-right: 12%;
    }
    .p-pinlanding{
        padding-left: 20%;
        padding-right: 20%;
    }
}

.td-even{
    background-color: var(--warna-background-semantic) !important;
}

div.stockOps > div {
    background-image: linear-gradient(var(--warna-btn-dark), var(--warna-btn-dark-hover)) !important;
}

.table-striped-trans > tbody > tr:nth-of-type(even) {
    background-color: var(--warna-hover-menu);
}

.hover-tables:hover{
    background-color: var(--warna-hover-menu) !important;
}

.d-border-aggrid-right{
    border-right: 1px solid var(--warna-hover-menu)!important;
}

/*for grid odd*/
div.ag-theme-balham-dark.ag-striped-odd.yellow-hover > div > div > div > div > div > div > div > div > div.ag-row-even:hover {
    background-color: #ffc200 !important; color:white; }
div.ag-theme-balham-dark.ag-striped-odd.yellow-hover > div > div > div > div > div > div > div > div > div.ag-row-odd:hover {
    background-color: #ffc200 !important; color:white; }

/*div.ag-theme-balham-dark.ag-striped-odd > div > div > div > div > div > div > div > div > div.ag-row-odd:hover {*/
    /*background-color: var(--warna-btn-dark-hover)!important; }*/
/*div.ag-theme-balham-dark.ag-striped-odd > div > div > div > div > div > div > div.ag-row-odd:hover {*/
    /*background-color: var(--warna-btn-dark-hover)!important; }*/

/*div.ag-theme-balham-dark.ag-striped-odd > div > div > div > div > div > div > div > div > div.ag-row-even:hover {*/
    /*background-color: var(--warna-btn-dark-hover)!important; }*/

/*div.ag-theme-balham-dark.ag-striped-odd > div > div > div > div > div > div > div.ag-row-even:hover {*/
    /*background-color: var(--warna-btn-dark-hover)!important; }*/

/*div.ag-theme-balham-dark.ag-striped-odd > div > div > div > div > div > div > div > div > div.ag-row-odd {*/
    /*background-color: var(--warna-bg-dark-grey) !important; }*/

/*div.ag-theme-balham-dark.ag-striped-odd > div > div > div > div > div > div > div.ag-row-odd {*/
    /*background-color: var(--warna-bg-dark-grey) !important; }*/

/*div.ag-theme-balham-dark.ag-striped-odd > div > div > div > div > div > div > div > div > div.ag-row-even {*/
    /*background-color: var(--warna-hover-menu) !important; }*/

/*div.ag-theme-balham-dark.ag-striped-odd > div > div > div > div > div > div > div.ag-row-even {*/
    /*background-color: var(--warna-hover-menu) !important; }*/

/*!*for grid even*!*/
div.ag-theme-balham-dark.ag-striped-even > div > div > div > div > div > div > div > div > div.ag-row-odd:hover {
    background-color: var(--warna-btn-dark-hover)!important; }
div.ag-theme-balham-dark.ag-striped-even > div > div > div > div > div > div > div.ag-row-odd:hover {
    background-color: var(--warna-btn-dark-hover)!important; }
div.ag-theme-balham-dark.ag-striped-even > div > div > div > div > div > div > div > div > div.ag-row-even:hover {
    background-color: var(--warna-btn-dark-hover)!important; }
div.ag-theme-balham-dark.ag-striped-even > div > div > div > div > div > div > div.ag-row-even:hover {
    background-color: var(--warna-btn-dark-hover)!important; }


/*div.ag-theme-balham-dark.ag-striped-even > div > div > div > div > div > div > div > div > div.ag-row-odd {*/
    /*background-color: var(--warna-bg-dark-grey) !important }*/
/*div.ag-theme-balham-dark.ag-striped-even > div > div > div > div > div > div > div.ag-row-odd {*/
    /*background-color: var(--warna-bg-dark-grey) !important }*/
/*div.ag-theme-balham-dark.ag-striped-even > div > div > div > div > div > div > div > div > div.ag-row-even {*/
    /*background-color: var(--warna-hover-menu)!important; }*/
/*div.ag-theme-balham-dark.ag-striped-even > div > div > div > div > div > div > div.ag-row-even {*/
    /*background-color: var(--warna-hover-menu)!important; }*/

/*!*for grid live trade*!*/
div.ag-theme-balham-dark.ag-striped-odd > span > div > div > div > div > div > div > div > div > div.ag-row-odd:hover {
    background-color: var(--warna-hover-menu); }
div.ag-theme-balham-dark.ag-striped-odd > span > div > div > div > div > div > div > div.ag-row-odd:hover {
    background-color: var(--warna-hover-menu); }
div.ag-theme-balham-dark.ag-striped-odd > span > div > div > div > div > div > div > div > div > div.ag-row-even:hover {
    background-color: var(--warna-hover-menu); }
div.ag-theme-balham-dark.ag-striped-odd > span > div > div > div > div > div > div > div.ag-row-even:hover {
    background-color: var(--warna-hover-menu); }

div.ag-theme-balham-dark.ag-striped-odd > span > div > div > div > div > div > div > div > div > div.ag-row-odd {
    background-color: var(--warna-table-striped) }
div.ag-theme-balham-dark.ag-striped-odd > span > div > div > div > div > div > div > div.ag-row-odd {
    background-color: var(--warna-table-striped) }
div.ag-theme-balham-dark.ag-striped-odd > span > div > div > div > div > div > div > div > div > div.ag-row-even {
    background-color: var(--warna-dasar); }
div.ag-theme-balham-dark.ag-striped-odd > span > div > div > div > div > div > div > div.ag-row-even {
    background-color: var(--warna-dasar); }
.ag-row-hover {
    /* putting in !important so it overrides the theme's styling as it hovers the row also */
    /*background-color: #dfdfff !important;*/
    background-color: var(--warna-btn-dark-hover)!important;
}


.h-100{
    height: 100px !important;
}

.even-td{
    background: var(--warna-background-semantic);
}
.td-bluelight{
    background-color: #5A7378;
}

.text-bips-dark{
    color: var(--warna-btn-dark) !important;
    background: -webkit-linear-gradient(var(--warna-btn-dark), var(--warna-btn-dark-hover)) !important;
    -webkit-background-clip: text!important;
    -webkit-text-fill-color: transparent!important;
}

.ui.selection.dropdown.grey{
    min-height: 31px!important;
}

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td{
    border : 1px solid var(--warna-hover-menu)!important;
}

.d-border-footer{
    border-top: var(--warna-scroll) 2px solid!important;
}

@media (min-width: 994px) {
    .h-84{
        height: 84px!important;
    }
    .tabheaderfill {
        height: 44.5px !important;
    }
    .h-33{
        height: 33px!important;
    }
    .h-45{
        height: 45px!important;
    }
    .h-30{
        height: 30px;
    }
    .h-48{
        height: 48px!important;
    }
    .h-49{
         height: 49px!important;
    }
    .h-40{
         height: 40px!important;
    }
    .h-27{
        height: 27px!important;
    }
}

.h-22{
     height: 22px!important;
 }.h-31{
      height: 31px!important;
  }.h-37{
        height: 37px!important;
    }.h-121{
         height: 121px!important;
     }.h-25{
           height: 25px!important;
       }.h-62{
            height: 62px!important;
        }.h-73{
             height: 73px!important;
         }.h-17{
              height: 17px!important;
          }.h-79{
                 height: 79px!important;
             }.h-77{
                  height: 77px!important;
              }
.h-32{
    height: 32px!important;
    /*font-size: 13px !important;*/
    background-image: linear-gradient(var(--warna-navy-even), var(--warna-navy-odd)) !important;
}

.scene{
    height: 48px!important;
}

div.main-header > .ui.menu .item{
    height:80px!important;
}

.ag-header-cell{
    border-right: 1px solid #88888880!important;
}.ag-header-group-cell{
     border-right: 1px solid #88888880!important;
 }
.ag-cell {
    overflow: hidden !important;
}
.grid-294{
    height: 294px;
}

.row:before, .row:after {display: none !important;}

.header-scale-menu{
    display: var(--header-menu-scale)!important;
}

.header-normal-menu{
    display: var(--header-menu)!important;
}

.danger-text{
    color: #DC3545!important;
}

.open > .dropdown-menu {
    background: var(--warna-bg-dark-grey);
}

.dropdown-menu.inner > li > a{
    color: var(--warna-text-basic);
}
.dropdown-menu.inner > li > a.active{
    color: white;
}

.dropdown-menu.inner > li > a:hover{
    color: var(--warna-scroll);
}

.anychart-label-input{
    background: var(--warna-bg-dark-grey);
    color: var(--warna-text-basic);
    border: var(--warna-d-border) solid 1px;
    border-radius: 0px;
}

.anychart-button-standard {
    box-shadow: none;
    background-color: #888888;
    background-image: linear-gradient(to bottom, var(--warna-bg-dark-grey), var(--warna-btn-dark));
    color: var(--warna-text-basic);
    border: 1px solid #dcdcdc;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.anychart-button-standard:hover {
    box-shadow: none;
    background-color: #888888;
    background-image: linear-gradient(to bottom, var(--warna-bg-dark-grey), var(--warna-bg-dark-grey));
    color: var(--warna-text-basic);
    border: 1px solid #dcdcdc;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.anychart-button-standard.anychart-button-checked {
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    background-color: #888888;
    background-image: linear-gradient(to bottom,var(--warna-bg-dark-grey),var(--warna-bg-dark-grey));
    border: 1px solid #cccccc;
    color: var(--warna-text-basic);
}

/*text{
    fill:var(--warna-text-basic)!important;
}*/

/*svg > g > g > g > path:first-child{
    fill: var(--warna-bg-trading-gray);
}*/

#pieChart > div > svg > g > g > g:nth-child(3) > path:first-child {
    stroke : none!important;
    fill : var(--warna-dasar)!important;
}#pieChart > div > svg > g > g > g:nth-child(2) > path:first-child {
     stroke : none!important;
     fill : var(--warna-dasar)!important;
 }

#stock-chart > #chart-container > div > svg > g > g > g:first-child > path {
    fill : var(--warna-bg-dark-grey)!important;
}

.ag-theme-balham-dark .ag-header-cell::after, .ag-theme-balham-dark .ag-header-group-cell::after {
    border-right: 1px solid #88888880!important;
}

.ag-header-group-cell-label{
    justify-content: center;
}

.ag-header-cell-label {
    justify-content: center;
}

.d-active {
    border: #0071bc solid 1px!important;
}

.col-sm-header-logo {
    flex: 0 0 7%;
    max-width: 7%;

}

.col-sm-header-menu {
    flex: 0 0 76%;
    max-width: 76%;

}

.col-sm-header-user {
    flex: 0 0 17.5%;
    max-width: 17.5%;
}

.col-sm-sidebar{
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-sm-contentbar{
    position: relative;
    min-height: 1px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 994px) {
    .col-sm-sidebar{
        float: left;
    }
    .col-sm-sidebar {
        width: 8%;
    }
    .col-sm-contentbar{
        float: left;
    }
    .col-sm-contentbar {
        width: 92%;
    }
    .px-sidebar {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .d-sidebar-potrait{
        display: block!important;
    }

    .d-sidebar-landscape{
          display: none!important;
    }
}


@media (max-width:993px) {
    .px-sidebar {
        padding-right: 2.25rem;
        padding-left: 2.25rem;
    }

    .d-sidebar-potrait{
        display: none!important;
    } .d-sidebar-landscape{
          display: block!important;
      } .d-sidebar-landscape-hover:hover{
            background-color: var(--warna-btn-dark-hover) !important;
        }
}

.ui.dropdown.grey .menu {
    cursor: auto;
    position: absolute;
    display: none;
    outline: 0;
    top: 100%;
    min-width: max-content;
    margin: 0;
    padding: 0 0;
    background: var(--warna-btn-dark)!important;
    font-size: 12px;
    text-shadow: none;
    text-align: left;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, .15);
    border: 1px solid rgba(34, 36, 38, .15);
    border-radius: .28571429rem;
    transition: opacity .1s ease;
    z-index: 11;
    will-change: transform, opacity;
}

.ui.selection.dropdown.grey {
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    transform: rotateZ(0);
    min-width: 0;
    min-height: 2.71428571em;
    background-image: linear-gradient(var(--warna-btn-dark), var(--warna-btn-dark-hover)) !important;
    display: inline-block;
    padding: 10px 10px 10px 5px!important;
    color: var(--warna-text-basic)!important;
    box-shadow: none;
    border: 1px solid var(--warna-d-border);
    border-radius: 0;
    transition: box-shadow .1s ease, width .1s ease;
}


html{
    scrollbar-color: #777777 #918D8D42!important;
}
#root{
    scrollbar-color: var(--warna-scroll) var(--warna-dasar)!important;
}

.op-05{
    opacity: .5!important;
}
.op-05:hover{
    opacity: 1!important;
}

.ui.input.dark>input {
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: .67857143em 1em;
    background: var(--warna-btn-dark)!important;
    border: 1px solid var(--warna-btn-dark);
    color: var(--warna-text-basic)!important;
    border-radius: 0rem;
    transition: box-shadow .1s ease, border-color .1s ease;
    box-shadow: none;
}

.buttonInside{
    position:relative;
    /*margin-bottom:10px;*/
}

.button-inside-input-login{
    position:absolute;
    right: 5px;
    top: 7px;
    border:none;
    height:20px;
    width:20px;
    border-radius:100%;
    outline:none;
    text-align:center;
    font-weight:bold;
    padding:2px;
    opacity: .5!important;
}
.button-inside-input{
    position:absolute;
    right: 5px;
    top: 4px;
    border:none;
    height:20px;
    width:20px;
    border-radius:100%;
    outline:none;
    text-align:center;
    font-weight:bold;
    padding:2px;
    opacity: .5!important;
}
.button-inside-input:hover,
.button-inside-input-login:hover{
    cursor:pointer;
    color: #0071BC!important;
    opacity: 1!important;
}
.button-inside-input:active,
.button-inside-input-login:active{
    cursor:pointer;
    opacity: .5!important;
}

.input-inside-button{
    height:25px;
    width:100%;
    padding-left:10px;
}

.d-border-danger{
    border: 1px solid #DC3545!important;
}.d-border-danger-bottom{
     border-bottom: 1px solid #DC3545!important;
 }.d-border-danger-top{
      border-top: 1px solid #DC3545!important;
  }.d-border-danger-right{
       border-right: 1px solid #DC3545!important;
   }.d-border-danger-left{
        border-left: 1px solid #DC3545!important;
    }

@media (min-width: 576px){
    .col-sm-3-6 {
        flex: 0 0 var(--column-col-sm-3-6) !important; /*25%|50%*/
        max-width: var(--column-col-sm-3-6) !important; /*25%|50%*/
    }
    .col-sm-2-4 {
        flex: 0 0 var(--column-col-sm-2-4) !important; /*16.666667%|33.333333%*/
        max-width: var(--column-col-sm-2-4) !important; /*16.666667%|33.333333%*/
    }
}
@media (max-width: 575px){
    .col-sm-3-6 {
        position: relative!important;
        min-height: 1px!important;
        width: 100% !important;
    }
    .col-sm-2-4 {
        position: relative!important;
        min-height: 1px!important;
        width: 100% !important;
    }
}

.myBtn {
    display: block;
    position: fixed;
    bottom: 5px;
    right: 5px;
    z-index: 99;
    font-size: 12px;
    border: none;
    outline: none;
    background-color: #0071BC;
    opacity: 0.1;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 6px;
}

.myBtn:hover {
    background-color: #0071BC;
    opacity: 1;
}

.scale80 {
    display: block;
    position: fixed;
    bottom: 15px;
    right: 800px;
    z-index: 99;
    font-size: 12px;
    border: none;
    outline: none;
    background-color: #0071BC;
    opacity: 0.03;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 6px;
}

.scale80:hover {
    background-color: #0071BC;
    opacity: 1;
}
.scale90 {
    display: block;
    position: fixed;
    bottom: 15px;
    right: 700px;
    z-index: 99;
    font-size: 12px;
    border: none;
    outline: none;
    background-color: #0071BC;
    opacity: 0.03;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 6px;
}

.scale90:hover {
    background-color: #0071BC;
    opacity: 1;
}
.scale100 {
    display: block;
    position: fixed;
    bottom: 15px;
    right: 600px;
    z-index: 99;
    font-size: 12px;
    border: none;
    outline: none;
    background-color: #0071BC;
    opacity: 0.03;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 6px;
}

.scale100:hover {
    background-color: #0071BC;
    opacity: 1;
}
.scale110 {
    display: block;
    position: fixed;
    bottom: 15px;
    right: 500px;
    z-index: 99;
    font-size: 12px;
    border: none;
    outline: none;
    background-color: #0071BC;
    opacity: 0.03;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 6px;
}

.scale110:hover {
    background-color: #0071BC;
    opacity: 1;
}
.scale120 {
    display: block;
    position: fixed;
    bottom: 15px;
    right: 400px;
    z-index: 99;
    font-size: 12px;
    border: none;
    outline: none;
    background-color: #0071BC;
    opacity: 0.03;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 6px;
}

.scale120:hover {
    background-color: #0071BC;
    opacity: 1;
}

.modeTheme {
    display: block;
    position: fixed;
    bottom: 55px;
    right: 10px;
    z-index: 99;
    font-size: 16px;
    border: none;
    outline: none;
    background-color: #0071BC;
    opacity: 0.2;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 6px;
}

.modeTheme:hover {
    background-color: #0071BC;
    opacity: 1;
}

.font-weight-bold {
    font-weight: 700!important
}

.text-underline{
    text-decoration: underline;
}

.ui.input.gray>input {
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: .67857143em 1em;
    background: var(--warna-d-border)!important;
    border: 1px solid var(--warna-d-border);
    color: #fff!important;
    border-radius: 0rem;
    transition: box-shadow .1s ease, border-color .1s ease;
    box-shadow: none;
}

.scroll-tbody {
    display: inline-block;
    height: 145px;
    overflow: auto
}

.scroll-tbody-120 {
    display: inline-block;
    height: 120px;
    overflow: auto
}

.scroll-tbody-tab {
    display: inline-block;
    height: 470px;
    overflow: auto
}

.scroll-tbody-220 {
    display: inline-block;
    height: 200px;
    overflow: auto
}

.scroll-tbody-245 {
    display: inline-block;
    height: 230px;
    overflow: auto
}

/* == Just general styling, not relevant :) == */

table {
    border-collapse: collapse;
}

th {
    background-color: var(--warna-dasar);
    color: var(--warna-text-basic);
}

th,
td {
    padding: 1em .5em;
}

thead tr:nth-child(1) th {
    position: -webkit-sticky!important;
    position: sticky!important;
    top: 0!important;
    text-align: center!important;
    z-index: 1;
}

.w-search{
    width: 50%!important;
}

.form-select{
    padding-left: 0.8rem!important;
    padding-right: 1.25rem!important;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@media (min-width: 576px){
    .col-mn-5 {
        flex: 0 0 20%!important;
        max-width: 20%!important;
    }

    .w-input {
        width: 450%!important;
    }
}
.col-mn-7{
    flex: 0 0 14.285% !important;
    max-width: 14.285% !important;
}

@media (max-width: 575px){
    .w-input {
        width: 100%!important;
        padding-left: 15px!important;
    }
}

.active-menu{
    background: #0071BC!important;
    color: white!important;
}

.button-border-grey{
    border-radius: 0px!important;
    border: #565252 solid .7px!important;
}

.col-mn-5, .col-mn-7 {
    position: relative!important;
    width: 100%!important;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 1127px) {
    .col-radio-sm{
        -ms-flex: 0 0 13%;
        flex: 0 0 13%;
        max-width: 13%;
        display: inline-block !important;
    }
    .col-title-radio {
        -ms-flex: 0 0 23%;
        flex: 0 0 23%;
        max-width: 23%;
        display: inline-block !important;
    }

    .col-radio {
        -ms-flex: 0 0 19.666667%;
        flex: 0 0 19.666667%;
        max-width: 19.666667%;
        display: inline-block !important;
    }

    .col-mbl-radio {
        -ms-flex: 0 0 60%;
        flex: 0 0 60%;
        max-width: 60%;
        display: inline-block!important;
    }

    .col-mbl-radio-o {
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%;
        display: inline-block!important;
    }
}

@media (min-width: 960px) and (max-width: 1126px){
    .col-title-radio {
        -ms-flex: 0 0 15%;
        flex: 0 0 15%;
        max-width: 15%;
        display: inline-block !important;
    }

    .col-radio {
        -ms-flex: 0 0 28.333333%;
        flex: 0 0 28.333333%;
        max-width: 28.333333%;
        display: inline-block !important;
    }

    .col-mbl-radio {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        display: inline-block!important;
    }

    .col-mbl-radio-o {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        display: inline-block!important;
    }
}

@media (min-width: 900px) and (max-width: 959px){
    .col-title-radio {
        -ms-flex: 0 0 15%;
        flex: 0 0 15%;
        max-width: 15%;
        display: inline-block !important;
    }

    .col-radio {
        -ms-flex: 0 0 28.333333%;
        flex: 0 0 28.333333%;
        max-width: 28.333333%;
        display: inline-block !important;
    }

    .col-mbl-radio {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        display: inline-block!important;
    }

    .col-mbl-radio-o {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        display: inline-block!important;
    }
}

@media (min-width: 860px) and (max-width: 899px){
    .col-title-radio {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
        display: inline-block !important;
    }
    .col-radio {
        -ms-flex: 0 0 26.6666667%;
        flex: 0 0 26.6666667%;
        max-width: 26.6666667%;
        display: inline-block !important;
    }

    .col-mbl-radio {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        display: inline-block!important;
    }

    .col-mbl-radio-o {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        display: inline-block!important;
    }
}

@media (min-width: 820px) and (max-width: 859px){
    .col-title-radio {
        -ms-flex: 0 0 17%;
        flex: 0 0 17%;
        max-width: 17%;
        display: inline-block !important;
    }
    .col-radio {
        -ms-flex: 0 0 27.6666667%;
        flex: 0 0 27.6666667%;
        max-width: 27.6666667%;
        display: inline-block !important;
    }

    .col-mbl-radio {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        display: inline-block!important;
    }

    .col-mbl-radio-o {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        display: inline-block!important;
    }
}

@media (min-width: 400px) and (max-width: 819px){
    .col-title-radio {
        -ms-flex: 0 0 16%;
        flex: 0 0 16%;
        max-width: 16%;
        display: inline-block !important;
    }
    .col-radio {
        -ms-flex: 0 0 28%;
        flex: 0 0 28%;
        max-width: 28%;
        display: inline-block !important;
    }

    .col-mbl-radio {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        display: inline-block!important;
    }

    .col-mbl-radio-o {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        display: inline-block!important;
    }
}

@media (max-width: 399px){
    .col-title-radio {
        -ms-flex: 0 0 16%;
        flex: 0 0 16%;
        max-width: 16%;
        font-size: 10px!important;
        display: inline-block !important;
    }
    .col-radio {
        -ms-flex: 0 0 28%;
        flex: 0 0 28%;
        max-width: 28%;
        font-size: 10px!important;
        display: inline-block !important;
    }

    .col-mbl-radio {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        display: inline-block!important;
    }

    .col-mbl-radio-o {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        display: inline-block!important;
    }
}

.title-radio{
    display: block;
    position: relative;
    font-weight: 300;
    font-size: 1.35em;
    padding: 20px 10px 25px 90px;
    margin: 10px auto;
    height: 30px;
    float: left;
    /*cursor: pointer;*/
    -webkit-transition: all 0.25s linear;
}

.title-radio-right{
    position: relative;
    font-weight: 300;
    font-size: 1.35em;
    padding: 20px 10px 25px 90px;
    margin-top: 0px;
    margin-bottom: 15px;
    margin-left: 20px!important;
    height: 30px;
    z-index: 9;
    /*cursor: pointer;*/
    -webkit-transition: all 0.25s linear;
}

.li-radio{
    color: var(--warna-text-basic);
    display: block;
    position: relative;
    float: left;
    width: 100%;
    /*border-bottom: 1px solid #333;*/
}

.ul-radio{
    color: var(--warna-text-basic);
    display: block;
    position: relative;
    float: left;
    width: 100%;
    /*border-bottom: 1px solid #333;*/
}

ul li input[type=radio]{
    position: absolute;
    visibility: hidden;
}

ul li label{
    display: block;
    position: relative;
    font-weight: 300;
    font-size: 1.25em!important;
    padding: 13px 50px 25px 48px;
    margin: 0px auto;
    height: 30px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
}

ul li:hover label{
    color: var(--warna-gray);
}

ul li .check{
    display: block;
    position: absolute;
    border: 2px solid var(--warna-text-basic);
    border-radius: 100%;
    height: 15px;
    width: 15px;
    top: 15px;
    left: 20px;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
}

ul li:hover .check {
    border: 2px solid var(--warna-gray);
}

ul li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 15px;
    width: 15px;
    top: 5px;
    left: 5px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}

input[type=radio]:checked ~ .check {
    border: 2px solid #0071BC;
}

input[type=radio]:checked ~ .check::before{
    /*background: #0DFF92;*/
}

input[type=radio]:checked ~ label{
    color: #0071BC;
}

.btn-grey {
    color: var(--warna-text-basic)!important;
    background-color: var(--warna-bg-dark-grey)!important;
    border-color: var(--warna-bg-dark-grey)!important;
}

.btn-grey:hover {
    color: var(--warna-text-basic)!important;
    background-color: var(--warna-hover-menu)!important;
    border-color: var(--warna-hover-menu)!important;
}

.btn-grey.focus,
.btn-grey:focus {
    box-shadow: 0 0 0 .2rem var(--warna-bg-dark-grey)!important;
}

.btn-grey.disabled,
.btn-grey:disabled {
    color: var(--warna-text-basic);
    background-color: var(--warna-bg-dark-grey)!important;
    border-color: var(--warna-bg-dark-grey)!important;
}

.btn-grey:not(:disabled):not(.disabled).active,
.btn-grey:not(:disabled):not(.disabled):active,
.show>.btn-grey.dropdown-toggle {
    color: var(--warna-text-basic);
    background-color: var(--warna-bg-dark-grey)!important;
    border-color: var(--warna-bg-dark-grey)!important;
}

.btn-grey:not(:disabled):not(.disabled).active:focus,
.btn-grey:not(:disabled):not(.disabled):active:focus,
.show>.btn-grey.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5)
}

.btn-brown {
    color: #ffffff!important;
    background-image: linear-gradient(#A24A20,#753618)!important;
    border-color: #A24A20!important;
}

.btn-brown:hover {
    color: #ffffff!important;
    background-color: #8b411d!important;
    border-color: #753618!important;
}

.btn-brown.focus,
.btn-brown:focus {
    box-shadow: 0 0 0 .2rem rgba(162, 74, 32, 0.5)!important;
}

.btn-brown.disabled,
.btn-brown:disabled {
    color: #ffffff!important;
    background-color: #A24A20!important;
    border-color: #A24A20!important;
}

.btn-brown:not(:disabled):not(.disabled).active,
.btn-brown:not(:disabled):not(.disabled):active,
.show>.btn-brown.dropdown-toggle {
    color: #ffffff!important;
    background-color: #8b411d!important;
    border-color: #753618!important;
}

.btn-brown:not(:disabled):not(.disabled).active:focus,
.btn-brown:not(:disabled):not(.disabled):active:focus,
.show>.btn-brown.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(162, 74, 32, 0.5) !important
}

.btn-grey-gray {
    color: #ffffff!important;
    background-image: linear-gradient(var(--warna-d-border),var(--warna-gray))!important;
    border-color: var(--warna-gray)!important;
}

.btn-grey-gray:hover {
    color: #ffffff!important;
    background-color: var(--warna-d-border)!important;
    border-color: var(--warna-gray)!important;
}

.btn-grey-gray.focus,
.btn-grey-gray:focus {
    box-shadow: 0 0 0 .2rem var(--warna-gray)!important;
}

.btn-grey-gray.disabled,
.btn-grey-gray:disabled {
    color: #ffffff!important;
    background-color: var(--warna-gray)!important;
    border-color: var(--warna-gray)!important;
}

.btn-grey-gray:not(:disabled):not(.disabled).active,
.btn-grey-gray:not(:disabled):not(.disabled):active,
.show>.btn-grey-gray.dropdown-toggle {
    color: #ffffff!important;
    background-color: var(--warna-d-border)!important;
    border-color: var(--warna-bg-dark-grey)!important;
}

.btn-grey-gray:not(:disabled):not(.disabled).active:focus,
.btn-grey-gray:not(:disabled):not(.disabled):active:focus,
.show>.btn-grey-gray.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem var(--warna-bg-dark-grey) !important
}

.fade-out {
    animation: fadeOut ease 2s;
    -webkit-animation: fadeOut ease 2s;
    -moz-animation: fadeOut ease 2s;
    -o-animation: fadeOut ease 2s;
    -ms-animation: fadeOut ease 2s;
    display: none;
}

.bg-grey-mystic {
    background-color: var(--warna-btn-dark)!important;
    border: var(--warna-btn-dark) solid 1px!important;
}

.bg-grey-0 {
    background-color: var(--warna-table-striped)!important;
    border: var(--warna-table-striped) solid 1px!important;
}

.bg-gold {
    background-color: #94752C!important;
    border: #94752C solid 1px!important;
    color :white!important;
}

.hover-gold:hover{
    background-color: #ad8735 !important;
    border: #ad8735 solid 1px!important;
}

.hover-gold:focus,
.hover-gold:active{
    border: #0071BC solid 1px!important;
}

a.bg-gold:focus,
a.bg-gold:hover,
button.bg-gold:focus,
button.bg-gold:hover {
    background-color: #765f22 !important;
    border: #765f22 solid 1px!important;
    color :white!important;
}

@keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

.fade-in {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
}


@keyframes fadeIn{
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

.ui-menu-padding{
    padding-bottom: 16.5px;
    padding-top: 10px;
}

.ul-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: var(--warna-dasar);
}

.li-menu-news {
    /*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
    width : 33.33%;
    float: left;
    /*background-color: var(--warna-bg-dark-grey);*/
    border-bottom:2px solid var(--warna-d-border);
}

.li-menu-news:last-child {
    border-left:none;
}

.li-menu-news:first-child {
    border-right:none;
}

.li-menu-trade {
    width : 35%;
    float: left;
    background-color: var(--warna-bg-dark-grey);
    border:1px solid var(--warna-d-border);
}

.li-menu-trade:last-child {
    border-left:none;
}

.li-menu-trade:first-child {
    border-right:none;
}

.li-menu-table {
    width : 33.333333%;
    float: left;
    background-color: var(--warna-bg-dark-grey);
    border:1px solid var(--warna-d-border);
}

.li-menu-table:last-child {
    border-left:none;
}

.li-menu-table:first-child {
    border-right:none;
}

.li-news-active {
    color:white!important;
    background-image: linear-gradient(#67AAD7,#0071BC) !important;
}

.li-trade-active {
    background-color: #0071BC;
    color: white!important;
}

.li-table-active {
    background-color: var(--warna-d-border);
    color:var(--warna-text-menu)!important;
}

.li-menu-watchlist {
    width : 50%;
    float: left;
    background-color: var(--warna-bg-dark-grey);
    border:1px solid var(--warna-d-border);
}

.li-menu-watchlist:last-child {
    border-left:none;
}

.li-menu-watchlist:first-child {
    border-right:none;
}

.li-menu {
    width : 20%;
    float: left;
    background-color: var(--warna-bg-dark-grey);
    border:1px solid var(--warna-d-border);
}

.li-menu:last-child {
    border-left:none;
}

.li-menu:first-child {
    border-right:none;
}

.li-menu-a-trade {
    display: block;
    color: var(--warna-text-basic);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none!important;

}

.li-menu-a-trade:hover:not(.active) {
    background-color: var(--warna-hover-menu);
}

.li-menu-a-news {
    display: block;
    color: var(--warna-text-basic);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none!important;
}

.li-menu-a-news:hover:not(.active) {
    background-color: var(--warna-hover-menu);
}

.li-menu-a {
    display: block;
    color: var(--warna-text-basic);
    text-align: center;
    padding: 14px 10px;
    text-decoration: none!important;
}

.li-menu-a:hover:not(.active) {
    background-color: var(--warna-hover-menu);
}

.li-active {
    background-color: #0071BC;
    color: white!important;
}

.text-border{
    color: #4D4D4E!important;
}

.w-45 {
    width: 45%!important
}

.w-60 {
    width: 60%!important
}

.w-50 {
    width: 40%!important
}

.text-tosca {
    color: #61dafb!important
}

.text-black {
    color: var(--warna-black-white)!important
}

.no-wrap{
    white-space: nowrap!important;
}

.paddingY-2{
    padding-bottom: .225rem!important;
    padding-top: .225rem!important;
}

.t-border-bottom-bold {
    border-bottom: var(--warna-d-border-bold) solid 2px!important;
}

.t-border-bottom {
    border-bottom: var(--warna-d-border-bold) solid 1px!important;
}

.t-border {
    border: var(--warna-d-border-bold) solid 1px!important;
}

.t-border-top {
    border-top: var(--warna-d-border-bold) solid 1px!important;
}

.t-border-top-bold {
    border-top: var(--warna-d-border-bold) solid 2px!important;
}

.d-border {
    border: var(--warna-d-border) solid 1px!important;
    /*border-radius: 0px;*/
}

.d-border-transparent-grey {
    border: var(--warna-bg-dark-grey) solid .1px!important;
    border-radius: 0px;
}

.d-border-bold {
    border: var(--warna-d-border-bold) solid 2px!important;
}

.d-border-top {
    border-top: var(--warna-d-border) solid 1px!important;
}

.d-border-bottom {
    border-bottom: var(--warna-d-border) solid 1px!important;
}

.d-border-bottom-bold {
    border-bottom: var(--warna-d-border) solid 2px!important;
}

.d-border-right {
    border-right: var(--warna-d-border) solid 1px!important;
}

.d-border-left {
    border-left: var(--warna-d-border) solid 1px!important;
}

.f-11-center{
    font-size: 11px;
    text-align: center;
}

.ml--15{
    margin-left: -15px;
}

.bg-black-trading{
    background-color: var(--warna-dasar) !important;
    color: var(--warna-text-basic)!important;
}

.bg-black-inactive{
    background-image: linear-gradient(var(--warna-black-white), var(--warna-inactive-gradient))!important;
    color: var(--warna-text-basic)!important;
}

.bg-grey{
    background-color: var(--warna-header-card)!important;
    color: var(--warna-text-basic)!important;
}

.bg-brown{
    color: #ffffff!important;
    background-color: #A24A20!important;
}

.d-border-active{
    border: var(--warna-gray) solid 1px!important;
    /*border-radius: 7px!important;*/
    padding: 20px 20px;
}

.d-border-active-tab{
    border: var(--warna-gray) solid 1px!important;
}

.d-border-table{
    border : 0.7px var(--warna-gray) solid!important;
}

.d-border-table-top{
    border-top : 0.7px var(--warna-gray) solid!important;
}

.d-border-table-bottom{
    border-bottom : 0.7px var(--warna-gray) solid!important;
}

.d-border-table-right{
    border-right : 0.7px var(--warna-gray) solid!important;
}

.d-border-table-left{
    border-left : 0.7px var(--warna-gray) solid!important;
}

.d-border-tr-gray{
    border-bottom : 0.7px var(--warna-gray) solid!important;
}

.d-border-tr-black{
    border-bottom : 0.7px var(--warna-d-border-black) solid!important;
}

.input-login{
    background: transparent;
    border: none;
    border-bottom: 1px solid #fcfcfc;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
}

.authentication-wrapper .authentication-inner{
    width : 100px!important;
}

.authentication-wrapper.authentication-1 .authentication-inner{
    max-width: 300px;
}

.click-pointer{
    cursor: pointer!important;
}

@media (min-width: 2101px){
    .p-login {
        padding-right: 30% !important;
        padding-left: 30% !important;
        padding-top: 24vh !important;
        /*padding-bottom: 10px !important;*/
        min-height: 100vh;
    }
}

@media (min-width: 1901px) and (max-width: 2100px){
    .p-login {
        padding-right: 30% !important;
        padding-left: 30% !important;
        padding-top: 18vh !important;
        /*padding-bottom: 10px !important;*/
        min-height: 100vh;
    }
}

@media (min-width: 1801px) and (max-width: 1900px){
    .p-login {
        padding-right: 30% !important;
        padding-left: 30% !important;
        padding-top: 17vh !important;
        /*padding-bottom: 10px !important;*/
        min-height: 100vh;
    }
}

@media (min-width: 1701px) and (max-width: 1800px){
    .p-login {
        padding-right: 30% !important;
        padding-left: 30% !important;
        padding-top: 14vh !important;
        /*padding-bottom: 10px !important;*/
        min-height: 100vh;
    }
}

@media (min-width: 1441px) and (max-width: 1700px){
    .p-login {
        padding-right: 30% !important;
        padding-left: 30% !important;
        padding-top: 12vh !important;
        /*padding-bottom: 10px !important;*/
        min-height: 100vh;
    }
}

@media (min-width: 1160px) and (max-width: 1440px){
    .p-login {
        padding-right: 30% !important;
        padding-left: 30% !important;
        padding-top: 9vh !important;
        /*padding-bottom: 10px !important;*/
        min-height: 100vh;
    }
}

@media (min-width: 1047px) and (max-width: 1159px) {
    .p-login {
        padding-right: 20% !important;
        padding-left: 20% !important;
        padding-top: 7vh !important;
        /*padding-bottom: 100px !important;*/
        min-height: 100vh;
    }
}

@media (min-width: 992px) and (max-width: 1046px) {
    .p-login {
        padding-right: 27.5% !important;
        padding-left: 27.5% !important;
        padding-top: 6vh !important;
        /*padding-bottom: 100px !important;*/
        min-height: 100vh;
    }
}

@media (max-width: 991px) {
    .p-login {
        padding-right: 5% !important;
        padding-left: 5% !important;
        padding-top: 4vh !important;
        /*padding-bottom: 100px !important;*/
        min-height: 100vh;
    }
}

@media (max-width: 1020px) {

    .col-smb-1,
    .col-smb-10,
    .col-smb-11,
    .col-smb-12,
    .col-smb-2,
    .col-smb-3,
    .col-smb-4,
    .col-smb-5,
    .col-smb-6,
    .col-smb-7,
    .col-smb-8,
    .col-smb-9 {
        float: left!important;
    }
    .col-smb-12 {
        width: 100%!important;
    }
    .col-smb-11 {
        width: 91.66666667%!important;
    }
    .col-smb-10 {
        width: 83.33333333%!important;
    }
    .col-smb-9 {
        width: 75%!important;
    }
    .col-smb-8 {
        width: 66.66666667%!important;
    }
    .col-smb-7 {
        width: 58.33333333%!important;
    }
    .col-smb-6 {
        width: 50%!important;
    }
    .col-smb-5 {
        width: 41.66666667%!important;
    }
    .col-smb-4 {
        width: 33.33333333%!important;
    }
    .col-smb-3 {
        width: 25%!important;
    }
    .col-smb-2 {
        width: 16.66666667%!important;
    }
    .col-smb-1 {
        width: 8.33333333%!important;
    }
}

.py-lbl-header{
    padding-top: .7rem!important;
    padding-bottom: .7rem!important;
    background-color: var(--warna-dasar)!important;
    border: #565252 solid 1px!important;
    border-radius: 5px!important;
}

.wrapper {
    display: flex;
    align-items: stretch;
}

.icon-size-20{
    font-size: 20px;
}

.header-menu{
    font-size: 10px;
    width: 60px;
}

.header-color{
    background-color: var(--warna-dasar)!important;
    border: #565252 solid 1px!important;
    padding: 1px!important;
}

.ui.menu .ui.dropdown .menu>.item:hover{
    color: var(--warna-black-white) !important;
    text-decoration: none!important;
    background-color: var(--warna-text-basic)!important;
}

.f-12-fix{
    font-size: 12px!important;
}

.f-13{
    font-size: 13px!important;
}

@media (min-width: 576px) and (max-width: 627px){
    .f-12-mini{
        font-size: 12px!important;
    }
}

@media (max-width: 1193px){
    .col-sm-1-3-mini {
        flex: 0 0 35.667%!important;
        max-width: 35.667%!important;
    }
}

@media (max-width: 1193px) and (min-width: 971px){
    .col-sm-1-3-mini {
        flex: 0 0 15.667%!important;
        max-width: 15.667%!important;
    }
}

@media (max-width: 970px) and (min-width: 743px){
    .col-sm-1-3-mini {
        flex: 0 0 20.667%!important;
        max-width: 20.667%!important;
    }
}

@media (max-width: 742px) and (min-width: 604px){
    .col-sm-1-3-mini {
        flex: 0 0 25.667%!important;
        max-width: 25.667%!important;
    }
}

@media (max-width: 603px) and (min-width: 576px){
    .col-sm-1-3-mini {
        flex: 0 0 35.667%!important;
        max-width: 35.667%!important;
    }
}

@media (max-width: 451px) and (min-width: 400px){
    .col-sm-1-3-mini {
        flex: 0 0 40.667%!important;
        max-width: 40.667%!important;
    }
}

@media (max-width: 399px){
    .col-sm-1-3-mini {
        flex: 0 0 50.667%!important;
        max-width: 50.667%!important;
    }
}

@media (min-width: 1194px){
    .col-sm-1-2-mini {
        flex: 0 0 15.667%!important;
        max-width: 15.667%!important;
    }
}

@media (min-width: 1316px) {
    :root{
        --header-menu-scale: none;
        --header-menu: block;
    }

    .d-xxl-block {
        display: block!important;
    }

    .d-sml-none {
        display: none!important;
    }

    .d-xsml-none {
        display: none!important;
    }

    .d-xxsml-none {
        display: none!important;
    }

    .d-xsm-none {
        display: none!important;
    }

    .fs-icon-bips{
        font-size: 2rem;
    }

    .fs-text-bips{
        font-size: 8px;
    }

    .col-sm-tab-scale {
        flex: 0 0 10.49% !important;
        max-width: 10.49%!important;
        display: inline-block!important;
    }

    .mr-nav{
        margin-right: 3.6rem!important;
        margin-left: 1.5rem!important;
    }

    .ml-nav{
        margin-left: 3.6rem!important;
        margin-right: 1.5rem!important;
    }

    .padding-6{
        padding: 0px 6.5px;
    }
    .padding-15{
        padding: 0px 15.3px;
    }
    .padding-7{
        padding: 0px 7.8px;
    }
    .padding-21{
        padding: 0px 21.09px;
    }
    .padding-11{
        padding: 0px 11.1px;
    }

    .img-avatar {
        vertical-align: middle;
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    .f-11{
        font-size: 11px;
    }

    .f-12{
        font-size: 12px;
    }

    .f-14{
        font-size: 14px;
    }

    .f-16,
    .f-16 > input[type='text']{
        font-size: 16px;
    }

    .f-10{
        font-size: 10px;
    }

    .f-8{
        font-size: 8px;
    }

    .cssmenu ul li a{
        padding: 15px 25px;
    }
}

@media (max-width: 1315px) and (min-width: 1177px) {
    .d-xxl-none {
        display: none!important;
    }

    .d-sml-block {
        display: block!important;
    }

    .d-xsml-none {
        display: none!important;
    }

    .d-xxsml-none {
        display: none!important;
    }

    .d-xsm-none {
        display: none!important;
    }

    .col-sm-tab {
        flex: 0 0 10.49% ;
        max-width: 10.49%;
        display: inline-block;
    }

    .fs-icon-bips{
        font-size: 1.9rem;
    }

    .fs-text-bips{
        font-size: 6px;
    }

    .padding-t5{
        padding: 5px 0px 0px 0px;
    }

    .menuscaleheader{
        padding: 9px;
    }

    .img-avatar {
        vertical-align: middle;
        width: 42px;
        height: 42px;
        border-radius: 50%;
    }

    .f-11{
        font-size: 10px;
    }

    .f-12{
        font-size: 11px;
    }

    .f-xs-14 {
        font-size: 11px;
    }

    .f-14 {
        font-size: 13px;
    }

    h5{
        font-size: 13px!important;
    }

    .f-16 > input[type='text'],
    .f-16 {
        font-size: 15px;
    }

    .cssmenu ul li a{
        padding: 15px 22px;
    }

    .f-10{
        font-size: 9px;
    }

    .f-8{
        font-size: 7px;
    }
}

@media (max-width: 1176px) and (min-width: 1117px){
    .d-xxl-none {
        display: none!important;
    }

    .d-sml-block {
        display: block!important;
    }

    .d-xsml-none {
        display: none!important;
    }

    .d-xxsml-none {
        display: none!important;
    }

    .d-xsm-none {
        display: none!important;
    }

    .col-sm-tab {
        flex: 0 0 10.49%;
        max-width: 10.49%;
        display: inline-block;
    }

    .fs-icon-bips{
        font-size: 1.9rem;
    }

    .fs-text-bips{
        font-size: 5.9px;
    }

    .padding-t5{
        padding: 5px 0px 0px 0px;
    }

    .menuscaleheader{
        padding: 9px 5px;
    }

    .img-avatar {
        vertical-align: middle;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .f-11{
        font-size: 9px;
    }

    .f-12{
        font-size: 10px;
    }

    .f-xs-14 {
        font-size: 10px;
    }

    .f-16 > input[type='text'],
    .f-16 {
        font-size: 14px;
    }

    .f-14 {
        font-size: 12px;
    }

    h5{
        font-size: 12px!important;
    }

    .cssmenu ul li a{
        padding: 15px 19px;
    }

    .f-10{
        font-size: 9px;
    }

    .f-8{
        font-size: 7px;
    }
}

@media (max-width: 1116px) and (min-width: 1021px){
    .d-xxl-none {
        display: none!important;
    }

    .d-sml-block {
        display: block!important;
    }

    .d-xsml-none {
        display: none!important;
    }

    .d-xxsml-none {
        display: none!important;
    }

    .d-xsm-none {
        display: none!important;
    }

    .col-sm-tab {
        flex: 0 0 10.49%;
        max-width: 10.49%;
        display: inline-block;
    }

    .fs-icon-bips{
        font-size: 1.9rem;
    }

    .fs-text-bips{
        font-size: 5.9px;
    }

    .padding-t5{
        padding: 5px 0px 0px 0px;
    }

    .menuscaleheader{
        padding: 9px 5px;
    }

    .img-avatar {
        vertical-align: middle;
        width: 33px;
        height: 33px;
        border-radius: 50%;
    }

    .f-11{
        font-size: 9px;
    }

    .f-12{
        font-size: 10px;
    }

    .f-xs-14 {
        font-size: 10px;
    }

    .f-16 > input[type='text'],
    .f-16 {
        font-size: 14px;
    }

    .f-14 {
        font-size: 12px;
    }

    h5{
        font-size: 12px!important;
    }

    .cssmenu ul li a{
        padding: 15px 19px;
    }

    .f-10{
        font-size: 8px;
    }

    .f-8{
        font-size: 6px;
    }
}

@media (max-width: 1020px) and (min-width: 846px){
    .d-xxl-none {
        display: none!important;
    }

    .d-sml-none {
        display: none!important;
    }

    .d-xsml-block {
        display: block!important;
    }

    .d-xxsml-none {
        display: none!important;
    }

    .d-xsm-none {
        display: none!important;
    }

    .col-sm-tab {
        flex: 0 0 10.39%;
        max-width: 10.39%;
        display: inline-block;
    }

    .fs-icon-bips{
        font-size: 2rem;
    }

    .fs-text-bips{
        font-size: 8px;
    }

    .f-11{
        font-size: 9px;
    }

    .f-12{
        font-size: 10px;
    }

    .f-xs-14 {
        font-size: 10px;
    }

    .f-16 > input[type='text'],
    .f-16 {
        font-size: 14px;
    }

    .f-14 {
        font-size: 12px;
    }

    h5{
        font-size: 12px!important;
    }
    h3{
        font-size: 20px!important;
    }

    .cssmenu ul li a{
        padding: 15px 9px;
    }

    .f-10{
        font-size: 7px;
    }

    .f-8{
        font-size: 5px;
    }

    .btn{
        font-size: 10px!important;
    }
}

@media (max-width: 845px) and (min-width: 756px){
    .d-xxl-none {
        display: none!important;
    }

    .d-sml-none {
        display: none!important;
    }

    .d-xsml-none {
        display: none!important;
    }

    .d-xxsml-block {
        display: block!important;
    }

    .d-xsm-none {
        display: none!important;
    }

    .col-sm-tab {
        flex: 0 0 10.285%;
        max-width: 10.285%;
        display: inline-block;
    }

    .img-avatar {
        vertical-align: middle;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .fs-icon-bips{
        font-size: 1.5rem;
    }

    .fs-text-bips{
        font-size: 7px;
    }

    .f-11{
        font-size: 6px;
    }

    .f-12{
        font-size: 7px;
    }

    .f-xs-14 {
        font-size: 7px;
    }

    .f-16 > input[type='text'],
    .f-16 {
        font-size: 11px;
    }

    .f-14 {
        font-size: 9px;
    }

    h5{
        font-size: 9px!important;
    }
    h3{
        font-size: 18px!important;
    }

    .btn{
        font-size: 7px!important;
    }

    .cssmenu ul li a{
        padding: 15px 19px;
    }
}

@media (max-width: 755px) and (min-width: 576px){
    .d-xxl-none {
        display: none!important;
    }

    .d-sml-none {
        display: none!important;
    }

    .d-xsml-none {
        display: none!important;
    }

    .d-xxsml-none {
        display: none!important;
    }

    .d-xsm-block {
        display: block!important;
    }

    .col-sm-tab {
        flex: 0 0 10.19%;
        max-width: 10.19%;
        display: inline-block;
    }

    .fs-icon-bips{
        font-size: 1.5rem;
    }

    .fs-text-bips{
        font-size: 6.2px;
    }

    .col-mbl-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
        display: inline-block!important;
    }
    .col-mbl-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
        display: inline-block!important;
    }
    .col-mbl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
        display: inline-block!important;
    }
    .col-mbl-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        display: inline-block!important;
    }
    .col-mbl-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
        display: inline-block!important;
    }
    .col-mbl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        display: inline-block!important;
    }

    .f-11{
        font-size: 8px;
    }

    .f-12{
        font-size: 9px;
    }

    .f-xs-14 {
        font-size: 9px;
    }

    .f-16 > input[type='text'],
    .f-16 {
        font-size: 13px;
    }

    .f-14 {
        font-size: 11px;
    }

    h5{
        font-size: 11px!important;
    }
    h3{
        font-size: 16px!important;
    }

    .cssmenu ul li a{
        padding: 15px 7px;
    }

    .f-10{
        font-size: 6px;
    }

    .f-8{
        font-size: 4px;
    }

    .btn{
        font-size: 9px!important;
    }
}

@media (max-width: 575px){
    .d-xxl-none {
        display: none!important;
    }

    .d-sml-none {
        display: none!important;
    }

    .d-xsml-none {
        display: none!important;
    }

    .d-xxsml-none {
        display: none!important;
    }

    .d-xsm-block {
        display: block!important;
    }

    .col-mbl-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
        display: inline-block!important;
    }
    .col-mbl-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
        display: inline-block!important;
    }
    .col-mbl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
        display: inline-block!important;
    }
    .col-mbl-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        display: inline-block!important;
    }
    .col-mbl-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
        display: inline-block!important;
    }
    .col-mbl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        display: inline-block!important;
    }

    .f-11{
        font-size: 8px;
    }

    .f-12{
        font-size: 9px;
    }

    .f-xs-14 {
        font-size: 9px;
    }

    .f-16 > input[type='text'],
    .f-16 {
        font-size: 13px;
    }

    .f-14 {
        font-size: 11px;
    }

    h5{
        font-size: 11px!important;
    }
    h3{
        font-size: 16px!important;
    }

    .cssmenu ul li a{
        padding: 15px 7px;
    }

    .f-10{
        font-size: 6px;
    }

    .f-8{
        font-size: 4px;
    }

    .btn{
        font-size: 9px!important;
    }
}

@media (max-width: 575px) and (min-width: 546px){
    .col-sm-tab {
        flex: 0 0 9.9%;
        max-width: 9.9%;
        display: inline-block;
    }

    .fs-icon-bips{
        font-size: 1rem;
    }

    .fs-text-bips{
        font-size: 7px;
    }
} @media (max-width: 545px) and (min-width: 480px) {
    .col-sm-tab {
        flex: 0 0 9.85%;
        max-width: 9.85%;
        display: inline-block;
    }

    .fs-icon-bips{
        font-size: 1rem;
    }

    .fs-text-bips{
        font-size: 6px;
    }
} @media (max-width: 479px) and (min-width: 429px){
    .col-sm-tab {
        flex: 0 0 9.7%;
        max-width: 9.7%;
        display: inline-block;
    }

    .fs-icon-bips{
        font-size: 1rem;
    }

    .fs-text-bips{
        font-size: 5.5px;
    }
} @media (max-width: 428px) and (min-width: 401px){
    .col-sm-tab {
        flex: 0 0 9.6%;
        max-width: 9.6%;
        display: inline-block;
    }

    .fs-icon-bips{
        font-size: 1rem;
    }

    .fs-text-bips{
        font-size: 5px;
    }
} @media (max-width: 400px) and (min-width: 365px){
    .col-sm-tab {
        flex: 0 0 9.45%;
        max-width: 9.45%;
        display: inline-block;
    }

    .fs-icon-bips{
        font-size: 1rem;
    }

    .fs-text-bips{
        font-size: 4.5px;
    }
} @media (max-width: 364px) and (min-width: 336px){
    .col-sm-tab {
        flex: 0 0 9.3%;
        max-width: 9.3%;
        display: inline-block;
    }

    .fs-icon-bips{
        font-size: 1rem;
    }

    .fs-text-bips{
        font-size: 4px;
    }
} @media (max-width: 335px){
    .col-sm-tab {
        flex: 0 0 9%;
        max-width: 9%;
        display: inline-block;
    }

    .fs-icon-bips{
        font-size: 1rem;
    }

    .fs-text-bips{
        font-size: 3.5px;
    }
}

@media (min-width: 756px){
    .toggler-menu{
        width: 50%;
        border-radius: 3px;
        padding: 5px 5px;
    }
}
@media (max-width: 755px){
    .toggler-menu{
        width: 100%;
        border-radius: 3px;
        padding: 5px 5px;
    }
}

.ph-8{
    padding-right:6px!important;
    padding-left:6px!important;
}

.f-25{
    font-size: 25px;
}

.f-18{
    font-size: 18px;
}

.text-transform-none{
    text-transform: none!important;
}

.text-peach{
    color: #E3B494!important;
}
.bg-peach{
    background: #E3B494!important;
    color: var(--warna-black-white)!important;
}

.bg-info{
    background: #17A2B8!important;
    color: var(--warna-black-white)!important;
}

.bg-dark-grey{
    background: var(--warna-bg-dark-grey)!important;
    color: var(--warna-text-basic)!important;
}

.menu-dropdown{
    background-color:var(--warna-dasar)!important;
    border:#565252 solid 1px!important;
}

.text-gray-tradding{
    color: #565252 !important;
}

.text-dark{
    color: var(--warna-d-border)!important;
}

.bg-gray-tradding{
    background-color: var(--warna-gray)!important;
    color: white!important;
}

.border-gray-tradding{
    border: 0.5px solid var(--warna-gray)!important;
}

.bg-mystic-trading{
    background-color: #262626!important;
}

.cursor-menu{
    cursor: pointer!important;
}

.img-round-icon {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    height: 70px;
    padding: 5px;
    border-radius: 50%;
}

.img-loading {
    vertical-align: middle;
    width: 70%;
    height: 70%;
}

.col-sidebar{
    flex: 0 0 5.333%;
    max-width: 8.333333%;
}

.bg-sidebar-black {
    background-image: linear-gradient(to top, var(--warna-black-white-gradient),var(--warna-black-white));
    width: auto!important;
}

.bg-sm-header{
    font-size: 1rem;
    background-image: linear-gradient(var(--warna-black-white),var(--warna-black-white-gradient))!important;
}

.navbar-trading {
    background-image: linear-gradient(var(--warna-black-white-gradient),var(--warna-black-white));
    color: white;
    width: auto!important;
}

.cssmenu {
    background-image: linear-gradient(var(--warna-black-white-gradient),var(--warna-black-white));
    width: auto!important;
}
.cssmenu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1;
    display: block;
    zoom: 1;
}
.cssmenu ul:after {
    content: " ";
    display: block;
    font-size: 0;
    height: 0;
    clear: both;
    visibility: hidden;
}
.cssmenu ul li {
    display: inline-block;
    padding: 0;
    margin: 0;
}
.cssmenu.align-right ul li {
    float: right;
}
.cssmenu.align-center ul {
    text-align: center;
}
.cssmenu ul li a {
    color: var(--warna-cssmenu);
    text-decoration: none;
    display: block;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    position: relative;
    -webkit-transition: color .25s;
    -moz-transition: color .25s;
    -ms-transition: color .25s;
    -o-transition: color .25s;
    transition: color .25s;
}
.cssmenu ul li a:hover {
    color: #0071BC;
}
.cssmenu ul li a:hover:before {
    width: 100%;
}
.cssmenu ul li a:after {
    content: "|";
    display: none;
    position: absolute;
    right: -3px;
    top: 19px;
    height: 6px;
    width: 6px;
    /*background: #565252;*/
    /*opacity: .5;*/
    font-weight: bold;
}
.cssmenu ul li a:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 0;
    background: #0071BC;
    -webkit-transition: width .25s;
    -moz-transition: width .25s;
    -ms-transition: width .25s;
    -o-transition: width .25s;
    transition: width .25s;
}
.cssmenu ul li.last > a:after,
.cssmenu ul li:last-child > a:after {
    display: none;
}
.cssmenu ul li.active a {
    color: white!important;
}
.cssmenu ul li.active a:before {
    width: 100%;
}
.cssmenu.align-right li.last > a:after,
.cssmenu.align-right li:last-child > a:after {
    display: block;
}
.cssmenu.align-right li:first-child a:after {
    display: none;
}

.cssmenumodal {
    background: var(--warna-header-card);
    width: auto!important;
}
.cssmenumodal ul {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1;
    display: block;
    zoom: 1;
}
.cssmenumodal ul:after {
    content: " ";
    display: block;
    font-size: 0;
    height: 0;
    clear: both;
    visibility: hidden;
}
.cssmenumodal ul li {
    display: inline-block;
    padding: 0;
    margin: 0;
}
.cssmenumodal.align-right ul li {
    float: right;
}
.cssmenumodal.align-center ul {
    text-align: center;
}
.cssmenumodal ul li a {
    color: var(--warna-cssmenu);
    text-decoration: none;
    display: block;
    padding: 15px 25px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    position: relative;
    -webkit-transition: color .25s;
    -moz-transition: color .25s;
    -ms-transition: color .25s;
    -o-transition: color .25s;
    transition: color .25s;
}
.cssmenumodal ul li a:hover {
    color: #0071BC;
}
.cssmenumodal ul li a:hover:before {
    width: 100%;
}
.cssmenumodal ul li a:after {
    content: "|";
    display: none;
    position: absolute;
    right: -3px;
    top: 19px;
    height: 6px;
    width: 6px;
    /*background: #565252;*/
    /*opacity: .5;*/
    font-weight: bold;
}
.cssmenumodal ul li a:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 0;
    background: #0071BC;
    -webkit-transition: width .25s;
    -moz-transition: width .25s;
    -ms-transition: width .25s;
    -o-transition: width .25s;
    transition: width .25s;
}
.cssmenumodal ul li.last > a:after,
.cssmenumodal ul li:last-child > a:after {
    display: none;
}
.cssmenumodal ul li.active a {
    color: white;
}
.cssmenumodal ul li.active a:before {
    width: 100%;
}
.cssmenumodal.align-right li.last > a:after,
.cssmenumodal.align-right li:last-child > a:after {
    display: block;
}
.cssmenumodal.align-right li:first-child a:after {
    display: none;
}

.bg-trading-gray {
    background-color: var(--warna-bg-trading-gray) !important;
    color: var(--warna-cssmenu) !important;
}

/*@media (max-width: 1440px) {*/
.card-trading{
    height: var(--card-trading);
    border: 1px solid var(--warna-header-card);
    border-radius: .25rem;
}

.tab-chart{
    height: var(--card-75);
    border: 1px solid var(--warna-header-card);
    border-radius: .25rem;
}

.card-chart{
    /*height: 390px;*/
    height: var(--card-trading);
    border: 1px solid var(--warna-header-card);
    border-radius: .25rem;
}

.card-355{
    height: var(--card-355);
}

.card-500{
    height: var(--card-500);
}


.card-592{
    height: var(--card-592);
}

.card-580{
    height: var(--card-580);
}

.card-530{
    height: var(--card-530);
}

.card-170{
    height: var(--card-170)
}

.card-470{
    height: var(--card-470);
}

.card-558{
    height: var(--card-558);
}

.card-75{
    height: var(--card-75);
}

.card-600{
    height: var(--card-600);
}

.card-575{
    height: var(--card-575);
}

.card-527{
    height: var(--card-527);
}

.card-452{
    height: var(--card-452) !important;
}

.card-487{
    height: var(--card-487);
}

.card-305{
    height: var(--card-305);
}

.card-221{
    height: var(--card-221);
}

.card-194{
    height: var(--card-194);
}

.card-448{
    height: var(--card-448);
}

.card-478{
    height: var(--card-478);
}

.card-472{
    height: var(--card-472);
}

.card-381{
    height: var(--card-381);
}

.card-155{
    height: var(--card-155);
}

.card-233{
    height: var(--card-233);
}

.card-202{
    height: var(--card-202)!important;
}

.card-515{
    height: var(--card-515);
}

.card-334{
    height: var(--card-334);
}

.card-230 {
    height: var(--card-230);
}

.card-175 {
    height: var(--card-175);
}

.card-175b {
    height: 140px;
}

.card-138 {
    height: var(--card-138);
}

.card-479 {
    height: var(--card-479);
}

.card-406 {
    height: var(--card-406);
}

.card-282 {
    height: var(--card-282);
}

.card-160{
    height: var(--card-160)!important;
}

.card-190{
    height: var(--card-190)!important;
}

.card-156{
    height: var(--card-156);
}

.card-111{
    height: var(--card-111);
}

.card-158{
    height: var(--card-158);
}

.card-30{
    height: var(--card-30);
}

.card-64{
    height: var(--card-64);
}

.card-321{
    height: var(--card-321);
}

.card-324{
    height: var(--card-324);
}

.card-372{
    height: var(--card-372);
}

.card-129{
    height: var(--card-129);
}

.card-257{
    height: var(--card-257);
}

.card-475{
    height: var(--card-475);
}

.card-169{
    height: var(--card-169);
}

.card-113{
    height: var(--card-113);
}

.card-281{
    height: var(--card-281);
}




.card-520{
    height: var(--card-520);
}

.card-392{
    height: var(--card-392);
}

.card-490{
    height: var(--card-490);
}

.card-400{
    height: var(--card-400);
}

.card-540{
    height: var(--card-540);
}

.card-265{
    height: var(--card-265);
}

.card-560 {
    height: var(--card-560);
}

.card-398 {
    height: var(--card-398);
}

.card-196 {
    height: var(--card-196);
}

.card-167 {
    height: var(--card-167);
}

.card-440{
    height: var(--card-440);
}

.card-121{
    height: var(--card-121);
}

.card-345{
    height: var(--card-345);
}

.card-297{
    height: var(--card-297);
}

.card-511{
    height: var(--card-511);
}

.card-433{
    height: var(--card-433);
}

.card-149{
    height: var(--card-149);
}

.card-586{
    height: var(--card-586);
}

.card-559{
    height: var(--card-559);
}

.card-279{
    height: var(--card-279);
}

.card-501{
    height: var(--card-501)!important;
}

/*}*/

.card-292{
    height: var(--card-292);
}

.card-213 {
    height: var(--card-213);
}

.card-199 {
    height: var(--card-199);
}

.card-249 {
    height: var(--card-249);
}

.card-225 {
    height: var(--card-225);
}

.card-grafik{
    height: var(--card-grafik);
}

.card-589 {
    height: var(--card-589);
}

.card-537 {
    height: var(--card-537);
}

.py-2-scale {
    padding-top: var(--py-2-scale)!important;
    padding-bottom: var(--py-2-scale)!important;
}

.py-form {
    padding-top: var(--py-form);
    padding-bottom: var(--py-form);
}

.myLiveTrade{
    zoom: var(--zoom-livetrade);
    -moz-transform: scale(var(--zoom-livetrade));
}

.my-sidebar{
    margin-top: var(--margin-top-sidebar);
    margin-bottom: var(--margin-bottom-sidebar);
}
.py-sidebar{
    padding-top: var(--margin-top-sidebar);
    padding-bottom: var(--margin-bottom-sidebar);
}

.list-group-item-portofolio{
    position: relative;
    display: block;
    padding: 15px 15px;
    margin-bottom: -1px;
    background-color: var(--warna-bg-trading-gray);
    border-top:  2px solid var( --warna-inactive-gradient);
    height : 57px;
}

.d-border-tr-gray-all {
    border: .7px solid var(--warna-gray)!important;
}

.card-250{
    height: 277px;
}

.card-430{
    height: 430px;
}

.card-375{
    height: 375px;
}

.card-mini{
    height: 150px;
}

.card-small{
    height: 160px;
}

.card-180{
    height: 180px;
}

.card-xmini{
    height: 135px;
}

.card-xs{
    height: 90px;
}

.card-475{
    height: 475px;
}

.card-100{
    height: 700px;
}

.card-320{
    height: 320px;
}

.card-215 {
    height: 215px;
}

.card-200 {
    height: 200px;
}

.card-tab-trading{
    height: 650px;
}

.card-325{
    height: 360px;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px var(--warna-dasar);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 20px var(--warna-scroll);
}

.scrollable{
    overflow-y: scroll;
    position: relative;
    scrollbar-color: var(--warna-scroll) var(--warna-dasar)!important;
}

@media (max-width: 575px) {
    .col-xs-1 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 2px;
        margin-top: 2px;
    }
}

.col-sm-1-2 {
    flex: 0 0 12.667%;
    max-width: 12.667%;
}

.col-sm-1-3 {
    flex: 0 0 15.667%;
    max-width: 15.667%;
}

.col-sm-1-4 {
    flex: 0 0 22.667%;
    max-width: 22.667%;
}

.col-sm-1-1 {
    flex: 0 0 10.667%;
    max-width: 10.667%;
}

@media (max-width: 1390px) {
    .logo-center {
        margin : .8%;
        padding: 30px 10px 30px 10px;
        color: #ffffff!important;
    }
}

@media (min-width: 1391px) and (max-width: 1428px) {
    .logo-center {
        margin : .85%;
        padding: 30px 10px 30px 10px;
        color: #ffffff!important;
    }
}

@media (min-width: 1429px) and (max-width: 1452px) {
    .logo-center {
        margin : .9%;
        padding: 30px 10px 30px 10px;
        color: #ffffff!important;
    }
}

@media (min-width: 1453px) and (max-width: 1476px) {
    .logo-center {
        margin : .95%;
        padding: 30px 10px 30px 10px;
        color: #ffffff!important;
    }
}

@media (min-width: 1477px) and (max-width: 1488px) {
    .logo-center {
        margin : 1%;
        padding: 30px 10px 30px 10px;
        color: #ffffff!important;
    }
}

@media (min-width: 1489px) and (max-width: 1524px) {
    .logo-center {
        margin : 1.1%;
        padding: 30px 10px 30px 10px;
        color: #ffffff!important;
    }
}

@media (min-width: 1525px) and (max-width: 1596px) {
    .logo-center {
        margin : 1.2%;
        padding: 30px 10px 30px 10px;
        color: #ffffff!important;
    }
}

@media (min-width: 1597px) and (max-width: 1656px) {
    .logo-center {
        margin : 1.3%;
        padding: 30px 10px 30px 10px;
        color: #ffffff!important;
    }
}

@media (min-width: 1657px) and (max-width: 1704px) {
    .logo-center {
        margin : 1.4%;
        padding: 30px 10px 30px 10px;
        color: #ffffff!important;
    }
}

@media (min-width: 1705px) and (max-width: 1760px) {
    .logo-center {
        margin : 1.5%;
        padding: 30px 10px 30px 10px;
        color: #ffffff!important;
    }
}

@media (min-width: 1761px) and (max-width: 1836px) {
    .logo-center {
        margin : 1.6%;
        padding: 30px 10px 30px 10px;
        color: #ffffff!important;
    }
}

@media (min-width: 1837px) and (max-width: 1908px) {
    .logo-center {
        margin : 1.7%;
        padding: 30px 10px 30px 10px;
        color: #ffffff!important;
    }
}

@media (min-width: 1909px){
    .logo-center {
        margin : 1.8%;
        padding: 30px 10px 30px 10px;
        color: #ffffff!important;
    }
}

.col-hd-9 {
    flex: 0 0 71.777777%;
    max-width: 71.777777%;

}

.col-hd-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;

}

.col-hd-2 {
    flex: 0 0 20%;
    max-width: 20%;

}

.f-10-center{
    font-size: 10px;
    text-align: center;
}

.f-9-center{
    font-size: 9px;
    text-align: center;
}

.f-9{
    font-size: 9px!important;
}

.text-align-center{
    text-align: center!important;
}

:not(:root):-webkit-full-screen::backdrop {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: var(--warna-dasar)!important;
}

body:-moz-full-screen::backdrop {
    background-color: var(--warna-dasar) !important;
}

body:fullscreen::backdrop {
    background-color: var(--warna-dasar) !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* CSS FIRMA */

.ui.steps .step.active {
    cursor: auto;
    background: #0071BC;
}

.ui.steps .step.active:after {
    background: #0071BC;
}
.ui.steps .step.active:before {
    background: #0071BC;
}

.ui.steps .step:after {
    width: 4em;
    height: 4em;
}

.ui.steps .step {
    padding: 0.142857em 2em;
}


.step-blue{
    background-color: #0071BC;
}
/*
.bs-stepper .step-trigger {
    background-color: #17A2B8;
} */

.more{
    margin-top: -5px;
    /* padding-left: 30px; */
}
.more-last{
    margin-top: -5px;
    padding-left:25px;
}
/* .active{
	background: #0070c1;
} */
#crumbs {
    text-align: center;
}

#crumbs ul {
    list-style: none;
    display: inline-table;
}
#crumbs ul li {
    display: inline;
}
#crumbs ul li a.active {
    background: #0070c4;
    color: white!important;
}

#crumbs ul li a.active:after {
    border-left-color: #0070c4;
}
/* #crumbs ul li a.active:before {
    border-left-color: #0070c4;
} */

#crumbs ul li a.active:hover {
    background: #034a81;
    color: white!important;
}

#crumbs ul li a.active:hover {
    border-left-color: #034a81;
    color: white!important;
}

/* ini aktifkan */
#crumbs ul li.active a{
    background: #033861;
}

#crumbs ul li.active a:after {
    content: "";
    border-top: 25px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 28px solid #033861;
    position: absolute;
    right: -25px;
    top: 0;
    z-index: 1;
}

#crumbs ul li a {
    display: block;
    float: left;
    width: 143px;
    height: 49px;
    background: var(--warna-header-card);
    text-align: center;
    padding-top: 17px;
    position: relative;
    margin: 3px 9px 0 0;
    font-size: 16px;
    text-decoration: none;
    color: var(--warna-text-basic);

}

#crumbs ul li a:after {
    content: "";
    border-top: 25px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 28px solid var(--warna-header-card);
    position: absolute; 
    right: -25px; 
    top: 0;
    z-index: 1;
}

#crumbs ul li a:before {
    content: "";
    border-top: 25px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 28px solid var(--warna-dasar);
    position: absolute; left: 0; top: 0;
}

#crumbs ul li a.active:hover:after {
    /* background: #034a81; */
    content: "";
    border-top: 25px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 28px solid #034a81;
    position: absolute; 
    right: -25px; 
    top: 0;
    z-index: 1;
}



#crumbs ul li:first-child a {
    border-top-left-radius: 0px; border-bottom-left-radius: 0px;
}
#crumbs ul li:first-child a:before {
    display: none;
}

#crumbs ul li:last-child a {
    padding-right: 80px;
    border-top-right-radius: 0px; border-bottom-right-radius: 0px;
}
#crumbs ul li:last-child a:after {
    display: none;
}


/* #crumbs ul li a:hover {
    background: #0070c4;
}
    #crumbs ul li a:hover:after {
        border-left-color: #0070c4;
    }

*/

.content-step{
    display: block;
    width: 780px;
    height: 528px;
    padding-top: 15px;
    /* align-text:center; */
    background: var(--warna-bg-dark-grey);
    /* margin-left: 287px; */
    /* overflow:scroll; */
    overflow-y: auto;
    overflow-x: hidden;

}
.tab-center{
    align-text:center !important;
}
.next-btn{
    padding-top: 5px;
    padding-right: 15px;
    padding-bottom: 10px;
    width: 780px;
    background: var(--warna-bg-dark-grey);
    /* margin-left: 287px; */


    /* height: 575px; */
}

/* .ui.column.grid>[class*="three wide"].column, .ui.grid>.column.row>[class*="three wide"].column, .ui.grid>.row>[class*="three wide"].column, .ui.grid>[class*="three wide"].column {
width: 21% !important;
border: 1px var(--warna-d-border) solid !important;
padding-left: 8px !important;
}
*/
.ui.grid>.row>.stretched.column, .ui.grid>.stretched.column:not(.row), .ui.grid>.stretched.row>.column, .ui.stretched.grid>.column, .ui.stretched.grid>.row>.column {
    /* border: 1px var(--warna-d-border) solid !important;
    border-left: 0px !important;
    width: 79% !important;
    padding-left: 0px;*/
    width: 81% !important;
}

.ui.modal>.content {
    align-items: center  !important;
}

.ui.segment[class*="bottom attached"] {
    background-color:transparent;
    border:1px var(--warna-d-border) !important;
}

.ui.vertical.menu .active.item {
    background-color: #0071BC;
    border: 0;
}

.ui.vertical.menu .item {
    /* height: 45px; */
    font-size: 14px !important;
    color: var(--warna-text-basic);

}

@media (min-height: 15px) and (max-width: 45px) {
    .ui.vertical.menu .item{width: 90%}
}

@media (min-height: 150px) and (max-width: 250px) {
    .ui.vertical.menu.fluid {
        width: 250px !important;
    }
}

.ui.vertical.menu {
    background-color: var(--warna-gray );
    border: 0;
}

.ui.menu .item {
    /*border-right: 0.25px solid var(--warna-text-header) !important;*/
    color:var(--warna-text-header);
}

.setting-title{
    padding-bottom: 20px;
}

.ui.input>input.black {
    background: var(--warna-dasar) !important;
    /* border-radius: 6.5px; */
}

.black-dropdown{
    background: var(--warna-dasar) !important;
    /* border-radius: 6.5px; */

}
/* .ui.search.dropdown {
    border-radius: 6.5px;
} */

.step1 a{
    width: 131px !important;
    padding-left: 16px;
    padding-top: 30px;
}
.step-last a{
    width: 175px !important;
    margin-right: 0px !important;

}

.setting{
    height: 390px !important;
    padding-bottom: 0;
}

.logo-dark-theme {
    display: block;
    text-indent: -9999px;
    width: 300px;
    height: 80px;
    background: url(img/svg-setting/dark-theme.svg) no-repeat;
    display: inline-block !important;
    margin: 0;
    margin-right: 10px;
    /* background-size: 100px 82px; */
}

.logo-light-theme {
    display: block;
    text-indent: -9999px;
    width: 300px;
    height: 80px;
    background: url(img/svg-setting/light-theme.svg) no-repeat;
    display: inline-block !important;
    margin: 0;
    margin-right: 10px;
    /* background-size: 100px 82px; */
}
.logo-btn-save {
    display: block;
    text-indent: -9999px;
    width: 13px;
    height: 13px;
    background: url(img/svg-setting/save.svg) no-repeat;
    display: inline-block !important;
    margin: 0;
    margin-right: 10px;
    /* background-size: 100px 82px; */
}

.logo-appereance {
    display: block;
    text-indent: -9999px;
    width: 16px;
    height: 16px;
    background: url(img/svg-setting/save.svg) no-repeat;
    display: inline-block !important;
    margin: 0;
    margin-right: 10px;
    /* background-size: 100px 82px; */
}

.radio_item{
    display: none !important;
}

.label_item {
    opacity: 0.1;
    color: white;
    cursor: pointer;
}

.label_item:checked {
    color: white;
}

.radio_item:checked + label {
    opacity: 1;
    /* color: white; */
    border: 1px solid #0071BC;
    width: 150px;
    height: 85px;
    border-radius:3px
}

.ui.column.grid>[class*="three wide"].column, .ui.grid>.column.row>[class*="three wide"].column, .ui.grid>.row>[class*="three wide"].column, .ui.grid>[class*="three wide"].column {
    padding-left: 0px;
}

/* CHECKBOX */

@keyframes hover-color {
    from {
        border-color: #c0c0c0; }
    to {
        border-color: #3e97eb; } }

.magic-radio,
.magic-checkbox {
    position: absolute;
    display: none; }

.magic-radio[disabled],
.magic-checkbox[disabled] {
    cursor: not-allowed; }

.magic-radio + label,
.magic-checkbox + label {
    position: relative;
    display: block;
    padding-left: 30px;
    cursor: pointer;
    vertical-align: middle; }
.magic-radio + label:hover:before,
.magic-checkbox + label:hover:before {
    animation-duration: 0.4s;
    animation-fill-mode: both;
    animation-name: hover-color; }
.magic-radio + label:before,
.magic-checkbox + label:before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: '';
    border: 1px solid #c0c0c0; }
.magic-radio + label:after,
.magic-checkbox + label:after {
    position: absolute;
    display: none;
    content: ''; }

.magic-radio[disabled] + label,
.magic-checkbox[disabled] + label {
    cursor: not-allowed;
    color: #e4e4e4; }
.magic-radio[disabled] + label:hover, .magic-radio[disabled] + label:before, .magic-radio[disabled] + label:after,
.magic-checkbox[disabled] + label:hover,
.magic-checkbox[disabled] + label:before,
.magic-checkbox[disabled] + label:after {
    cursor: not-allowed; }
.magic-radio[disabled] + label:hover:before,
.magic-checkbox[disabled] + label:hover:before {
    border: 1px solid #e4e4e4;
    animation-name: none; }
.magic-radio[disabled] + label:before,
.magic-checkbox[disabled] + label:before {
    border-color: #e4e4e4; }

.magic-radio:checked + label:before,
.magic-checkbox:checked + label:before {
    animation-name: none; }

.magic-radio:checked + label:after,
.magic-checkbox:checked + label:after {
    display: block; }

.magic-radio + label:before {
    border-radius: 50%; }

.magic-radio + label:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #3e97eb; }

.magic-radio:checked + label:before {
    border: 1px solid #3e97eb; }

.magic-radio:checked[disabled] + label:before {
    border: 1px solid #c9e2f9; }

.magic-radio:checked[disabled] + label:after {
    background: #c9e2f9; }

.magic-checkbox + label:before {
    border-radius: 3px; }

.magic-checkbox + label:after {
    top: 2px;
    left: 7px;
    box-sizing: border-box;
    width: 6px;
    height: 12px;
    transform: rotate(45deg);
    border-width: 2px;
    border-style: solid;
    border-color: #fff;
    border-top: 0;
    border-left: 0; }

.magic-checkbox:checked + label:before {
    border: #3e97eb;
    background: #3e97eb; }

.magic-checkbox:checked[disabled] + label:before {
    border: #c9e2f9;
    background: #c9e2f9; }


.modals.dimmer .ui.scrolling.modal {
    border: 1px var(--warna-gray) solid;
}

.ui.fullscreen.modal>.header {
    border:transparent;
    border-bottom: 1px var(--warna-gray) solid;
}

.ui.fullscreen.modal {
    border: 1px var(--warna-gray) solid !important;
}

/* .ag-header-container, .ag-floating-top-container, .ag-floating-bottom-container {
    background-color: #4D4E4E;
    border: 1px solid #c9e2f9;
} */

.text-blue{
    color: #0071BC;
}

.ver-center{
    vertical-align: middle !important;
}

/* .ag-header-gray .ag-header-row {
    background-color: var(--warna-d-border);
}

.ag-header-gray .ag-header-viewport {
    background-color: var(--warna-d-border);
} */

.table-bordered{
    border: 1px solid var(--warna-gray);
}
/*.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    cursor: inherit;
    display: block;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}
.custom-file-input::before {
    content: 'Select some files';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
}
.custom-file-input:hover::before {
    border-color: black;
}
.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
*/

.label_item {
    opacity: 0.3;
    color: white;
    cursor: pointer;
}

.ui.modal.fullscreen>.header {
    background: var(--warna-dasar);
}
.ui.modal.fullscreen>.content {
    background: var(--warna-dasar);
}

.black-textarea{
    background: var(--warna-dasar);
    border: 1px solid var(--warna-d-border);
    color: var( --warna-text-basic);
}

/* input[type="file"] {
    display: !important;
} */

/* input[type="file"] {
    display: none !important;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background:#0071BC;
} */

.fileUpload {
    position: relative;
    overflow: hidden;
    margin: 10px;
}
.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

textarea {
    resize: none;
}
/*zaky nambah untuk tab custom di stockpage*/
.linkCustomStockTab {
    font-size: 13px !important;
    padding: 7px 4px !important;
}

.mt-9{
    margin-top: 9px;
}
.mh-45{
    min-height: 45px;
}
.switchClass{
    padding-top: 14px;
    margin-left: 19vh;
}
.card_help{
    background: var(--warna-bg-dark-grey);
    min-height: 30px;
    font-size: 11px !important;
}
.card_help button{
    padding:1px 2px !important;
    font-size: 11px;
    min-height: 12px !important;
}
.card-530{
    height: 410px;
}
.radio {
    margin-top: 4px;
    margin-bottom: 2px;
}
.stockOptionInput {
    width: 70px !important;
}
.stockOptionInput2 {
    width: 150px !important;
}
.stockOptionInput, .stockOptionInput2{
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    /*text-indent: 0.1;*/
    text-overflow: '';
    box-shadow:none !important;
}

.stockOptionInput::-webkit-calendar-picker-indicator,.stockOptionInput2::-webkit-calendar-picker-indicator {
    display: none;
}
.n-border-left{
    border-left: none !important;
}
.n-border-right{
    border-right: none !important;
}
.n-border{
    border:none !important;
}
.n-border-bottom{
    border-bottom: none !important;
}
.stockOptionInput:hover ~ .input-group-addon, .stockOptionInput2:hover ~ .input-group-addon{
    /*box-shadow: 0 0 1px rgba(81, 203, 238, 1);*/
    border: 1px solid #0071BC;
}
.stockOptionInput{
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    text-overflow-ellipsis: true;
}

.input-group-addon{
    -webkit-transition: all 0.01s ease-in-out;
    -moz-transition: all 0.01s ease-in-out;
    -ms-transition: all 0.01s ease-in-out;
    -o-transition: all 0.01s ease-in-out;
}
.livetradeMenu{
    font-size: 1.25em!important;
    font-weight: 300;
    cursor:pointer;
    -webkit-transition: all 0.25s linear;
}
.livetradeMenuActive {
    font-size: 1.25em !important;
    font-weight: 300;
    cursor: pointer;
    color: #0d71bb;
    -webkit-transition: all 0.25s linear;
}


.card-watchlistAggrid{
    height: 524px;
}
.card-swt{
    height: 332px;
}
.card-bwt{
    height: 336px;
}
.card-twl{
    height: 362px;
}
.card-tradeAgW{
    height: 520px;
}
.table-market>thead>tr>th{
    padding: 10px 5px !important;
}
.table-market>tbody>tr>td{
    padding: 10px 5px !important;
}
.card-autOrd{
    height: 358px;
}
.card-autSend{
    height: 518px;
}
.card-mc {
    height: 332px;
}
.card-sib{
    height: 322px;
}

@media(min-width: 1280px) {
    @media (max-height: 693px){
        .table-market>thead>tr>th{
            padding: 12px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 11px 5px 11px 5px !important;
        }
        .card-mc{
            height: 333px;
        }
        .card-bwt{
            height: 363px;
        }
        .card-515{
            height: 520px;
        }
        .card-watchlistAggrid{
            height: 518px;
        }
        .card-autOrd{
            height: 363px;
        }
    }
    @media (min-height: 694px) and (max-height: 719px) {
        .table-market>thead>tr>th{
            padding: 12px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 11px 5px 11px 5px !important;
        }
        .card-bwt{
            height: 363px;
        }
        .card-515{
            height: 520px;
        }
        .card-watchlistAggrid{
            height: 518px;
        }
        .card-autOrd{
            height: 363px;
        }
    }
    @media (min-height: 720px) and (max-height: 767px){
        .table-market>thead>tr>th{
            padding: 13px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 13px 5px 12px 5px !important;
        }
        .card-mc{
            height: 360px;
        }
        .card-sib{
            height: 340px;
        }
        .card-bwt{
            height: 376px;
        }
        .card-watchlistAggrid{
            height: 538px;
        }
        .card-twl{
            height: 384px;
        }
        .card-tradeAgW{
            height: 547px;
        }
        .card-autOrd{
            height: 380px;
        }
        .card-autSend{
            height: 540px;
        }
    }
    @media (min-height: 768px) and (max-height: 799px){
        .card-mc{
            height: 409px;
        }
        .table-market>thead>tr>th,.table-market>tbody>tr>td{
            padding: 15px 5px !important;
        }
        .card-sib{
            height: 375px;
        }
        .card-bwt{
            height: 412px;
        }
        .card-watchlistAggrid{
            height: 585px;
        }
        .card-sellBuy{
            height: 545px;
        }
        .card-twl{
            height: 420px;
        }
        .card-tradeAgW{
            height: 596px;
        }
        .card-autOrd{
            height: 418px;
        }
        .card-autSend{
            height: 590px;
        }
    }
    @media (min-height: 800px){
        .card-mc{
            height: 433px;
        }
        .table-market>thead>tr>th {
            padding: 17px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 17px 5px 15px 5px !important;
        }
        .card-sib{
            height: 398px;
        }
        .card-watchlistAggrid{
            height: 620px;
        }
        .card-bwt{
            height: 439px;
        }
        .card-sellBuy{
            height: 580px;
        }
        .card-tradeAgW{
            height: 626px;
        }
        .card-twl{
            height: 443px;
        }
        .card-autSend{
            height: 620px;
        }
        .card-autOrd{
            height: 442px;
        }
    }
}
@media (min-width: 1360px) and (max-width: 1439px) {
    @media (max-height: 759px){
        .table-market>thead>tr>th{
            padding: 16px 5px 16px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 10px 5px 10px 5px !important;
        }
        .card-mc {
            height: 332px;
        }
        .card-sib{
            height: 322px;
        }
        .card-watchlistAggrid{
            height: 513px;
        }
        .card-bwt{
            height: 357px;
        }
        .card-sellBuy{
            height: 350px;
        }
        .card-twl{
            height: 362px;
        }
        .card-tradeAgW{
            height: 519px;
        }
        .card-autOrd{
            height: 362px;
        }
        .card-autSend{
            height: 518px;
        }
    }
    @media (min-height: 760px){
        .table-market>thead>tr>th{
            padding: 18px 5px 18px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 14px 5px!important;
        }
        .card-mc {
            height: 404px;
        }
        .card-sib{
            height: 375px;
        }
        .card-watchlistAggrid{
            height: 584px;
        }
        .card-bwt{
            height: 412px;
        }
        .card-sellBuy{
            height: 550px;
        }
        .card-twl{
            height: 418px;
        }
        .card-tradeAgW{
            height: 593px;
        }
        .card-autOrd{
            height: 416px;
        }
        .card-autSend{
            height: 590px;
        }
    }
}
@media (min-width: 1440px) and (max-width: 1679px){
    @media (max-height: 836px){
        .card-mc{
            height: 461px;
        }
        .table-market>thead>tr>th{
            padding: 18px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 18px 5px 17px 5px !important;
        }
        .card-sib{
            height: 416px;
        }
        .card-bwt{
            height: 455px;
        }
        .card-watchlistAggrid{
            height: 641px;
        }
        .card-sellBuy{
            height: 440px;
        }
        .card-twl{
            height: 460px;
        }
        .card-tradeAgW{
            height: 648px;
        }
        .card-autSend{
            height: 643px;
        }
        .card-autOrd{
            height: 460px;
        }
    }
    @media (min-height: 837px) and (max-height: 884px){
        .card-mc{
            height: 477px;
        }
        .table-market>thead>tr>th{
            padding: 20px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 19px 5px 17px 5px !important;
        }
        .card-sib{
            height: 425px;
        }
        .card-bwt{
            height: 464px;
        }
        .card-watchlistAggrid{
            height: 654px;
        }
        .card-sellBuy{
            height: 630px;
        }
        .card-twl{
            height: 470px;
        }
        .card-tradeAgW{
            height: 662px;
        }
        .card-autSend{
            height: 662px;
        }
        .card-autOrd{
            height: 470px;
        }
    }
    @media (min-height: 885px) and (max-height: 899px){
        .card-mc{
            height: 525px;
        }
        .table-market>thead>tr>th{
            padding: 22px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 21px 5px 20px 5px !important;
        }
        .card-sib{
            height: 459px;
        }
        .card-bwt{
            height: 500px;
        }
        .card-watchlistAggrid{
            height: 702px;
        }
        .card-sellBuy{
            height: 670px;
        }
        .card-twl{
            height: 508px;
        }
        .card-tradeAgW{
            height: 712px;
        }
        .card-autSend{
            height: 705px;
        }
        .card-autOrd{
            height: 505px;
        }
    }
    @media (min-height: 900px) and (max-height: 959px){
        .card-mc{
            height: 534px;
        }
        .table-market>thead>tr>th{
            padding: 22px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 21px 5px 21px 5px  !important;
        }
        .card-sib{
            height: 472px;
        }
        .card-watchlistAggrid{
            height: 715px;
        }
        .card-sellBuy{
            height: 700px;
        }
        .card-bwt{
            height: 511px;
        }
        .card-twl{
            height: 517px;
        }
        .card-tradeAgW{
            height: 724px;
        }
        .card-autSend{
            height: 720px;
        }
        .card-autOrd{
            height: 517px;
        }
    }
    @media (min-height: 960px){
        .card-mc{
            height: 593px;
        }
        .table-market>thead>tr>th{
            padding: 25px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 24px 5px !important;
        }
        .card-sib{
            height: 515px;
        }
        .card-bwt{
            height: 557px;
        }
        .card-watchlistAggrid{
            height: 777px;
        }
        .card-sellBuy{
            height: 770px;
        }
        .card-tradeAgW{
            height: 785px;
        }
        .card-twl{
            height: 564px;
        }
        .card-autSend{
            height: 777px;
        }
        .card-autOrd{
            height: 564px;
        }
    }
}
@media (min-width: 1680px) and (max-width: 1919px){
    @media (max-height: 1049px){
        .card-mc{
            height: 613px;
        }
        .table-market>thead>tr>th{
            padding: 26px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 25px 5px !important;
        }
        .card-sib{
            height: 526px;
        }
        .card-bwt{
            height: 568px;
        }
        .card-watchlistAggrid{
            height: 790px;
        }
        .card-sellBuy{
            height: 780px;
        }
        .card-tradeAgW{
            height: 798px;
        }
        .card-twl{
            height: 574px;
        }
        .card-autOrd{
            height: 574px;
        }
        .card-autSend{
            height: 793px;
        }
    }
    @media (min-height: 1050px){
        .card-mc{
            height: 681px;
        }
        .table-market>thead>tr>th{
            padding: 29px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 29px 5px 28px 5px !important;
        }
        .card-sib{
            height: 579px;
        }
        .card-watchlistAggrid{
            height: 867px;
        }
        .card-bwt{
            height: 624px;
        }
        .card-sellBuy{
            height: 860px;
        }
        .card-tradeAgW{
            height: 875px;
        }
        .card-twl{
            height: 632px;
        }
        .card-autSend{
            height: 870px;
        }
        .card-autOrd{
            height: 632px;
        }
    }
}
@media (min-width: 1920px) and (max-width: 2047px){
    @media (max-height: 1079px){
        .card-mc{
            height: 638px;
        }
        .table-market>thead>tr>th{
            padding: 28px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 26px 5px !important;
        }
        .card-sib{
            height: 549px;
        }
        .card-bwt{
            height: 591px;
        }
        .card-watchlistAggrid{
            height: 821px;
        }
        .card-sellBuy{
            height: 810px;
        }
        .card-twl{
            height: 596px;
        }
        .card-tradeAgW{
            height: 826px;
        }
        .card-autSend{
            height: 820px;
        }
        .card-autOrd{
            height: 596px;
        }
    }
    @media (min-height: 1080px) and (max-height: 1124px){
        .card-mc{
            height: 716px;
        }
        .table-market>thead>tr>th{
            padding: 32px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 30px 5px !important;
        }
        .card-sib{
            height: 603px;
        }
        .card-watchlistAggrid{
            height: 898px;
        }
        .card-bwt{
            height: 649px;
        }
        .card-sellBuy{
            height: 896px;
        }
        .card-twl{
            height: 654px;
        }
        .card-tradeAgW{
            height: 901px;
        }
        .card-autSend{
            height: 901px;
        }
        .card-autOrd{
            height: 654px;
        }
    }
    @media (min-height: 1125px) and (max-height: 1199px){
        .card-mc{
            height: 750px;
        }
        .table-market>thead>tr>th{
            padding: 32px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 32px 5px !important;
        }
        .card-sib{
            height: 634px;
        }
        .card-bwt{
            height: 683px;
        }
        .card-watchlistAggrid{
            height: 943px;
        }
        .card-sellBuy{
            height: 960px;
        }
        .card-tradeAgW{
            height: 948px;
        }
        .card-twl{
            height: 686px;
        }
        .card-autOrd{
            height: 686px;
        }
        .card-autSend{
            height: 948px;
        }
    }
    @media (min-height: 1200px){
        .card-mc{
            height: 833px;
        }
        .table-market>thead>tr>th{
            padding: 37px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 36px 5px !important;
        }
        .card-sib{
            height: 690px;
        }
        .card-watchlistAggrid{
            height: 1017px;
        }
        .card-bwt{
            height: 739px;
        }
        .card-sellBuy{
            height: 1030px;
        }
        .card-twl{
            height: 746px;
        }
        .card-tradeAgW{
            height: 1025px;
        }
        .card-autSend{
            height: 1019px;
        }
        .card-autOrd{
            height: 746px;
        }
    }
}
@media (min-width: 2048px) and (max-width: 2303px){
    @media(max-height: 1079px){
        .card-mc{
            height: 640px;
        }
        .table-market>thead>tr>th{
            padding: 27px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 27px 5px 26px 5px !important;
        }
        .card-sib{
            height: 549px;
        }
        .card-bwt{
            height: 591px;
        }
        .card-watchlistAggrid{
            height: 821px;
        }
        .card-sellBuy{
            height: 810px;
        }
        .card-twl{
            height: 596px;
        }
        .card-tradeAgW{
            height: 826px;
        }
        .card-autSend{
            height: 820px;
        }
        .card-autOrd{
            height: 596px;
        }
    }
    @media(min-height: 1080px){
        .card-mc{
            height: 714px;
        }
        .table-market>thead>tr>th{
            padding: 31px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 30px 5px !important;
        }
        .card-sib{
            height: 603px;
        }
        .card-bwt{
            height: 649px;
        }
        .card-watchlistAggrid{
            height: 900px;
        }
        .card-sellBuy{
            height: 900px;
        }
        .card-twl{
            height: 653px;
        }
        .card-tradeAgW{
            height: 905px;
        }
        .card-autSend{
            height: 900px;
        }
        .card-autOrd{
            height: 653px;
        }
    }
}
@media (min-width: 2304px) and (max-width: 2559px){
    @media(max-height: 1447px){
        .card-mc {
            height: 1008px;
        }

        .table-market > thead > tr > th {
            padding: 45px 5px !important;
        }

        .table-market > tbody > tr > td {
            padding: 45px 5px !important;
        }

        .card-sib {
            height: 816px;
        }

        .card-watchlistAggrid {
            height: 1190px;
        }
        .card-bwt {
            height: 870px;
        }
        .card-sellBuy {
            height: 1240px;
        }

        .card-tradeAgW {
            height: 1195px;
        }

        .card-twl {
            height: 874px;
        }

        .card-autSend {
            height: 1190px;
        }

        .card-autOrd {
            height: 874px;
        }
    }
    @media(min-height: 1448px){
        .card-mc {
            height: 1080px;
        }

        .table-market > thead > tr > th {
            padding: 49px 5px !important;
        }

        .table-market > tbody > tr > td {
            padding: 49px 5px 48px 5px !important;
        }

        .card-sib {
            height: 870px;
        }

        .card-watchlistAggrid {
            height: 1263px;
        }
        .card-bwt {
            height: 926px;
        }
        .card-sellBuy {
            height: 1330px;
        }
        .card-tradeAgW {
            height: 1270px;
        }
        .card-twl {
            height: 930px;
        }

        .card-autSend {
            height: 1265px;
        }

        .card-autOrd {
            height: 930px;
        }
    }
}
@media (min-width: 2560px){
    @media(max-height: 1599px){
        .card-mc{
            height: 1150px;
        }
        .table-market>thead>tr>th{
            padding: 52px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 52px 5px !important;
        }
        .card-sib{
            height: 926px;
        }
        .card-bwt{
            height: 986px;
        }
        .card-watchlistAggrid{
            height: 1343px;
        }
        .card-sellBuy{
            height: 1430px;
        }
        .card-twl{
            height: 990px;
        }
        .card-tradeAgW{
            height: 1350px;
        }
        .card-autSend{
            height: 1350px;
        }
        .card-autOrd{
            height: 990px;
        }
    }
    @media(min-height: 1600px) and (max-height: 1972px){
        .card-mc{
            height: 1230px;
        }
        .table-market>thead>tr>th{
            padding: 56px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 57px 5px 55px 5px !important;
        }
        .card-sib{
            height: 981px;
        }
        .card-bwt{
            height: 1042px;
        }
        .card-watchlistAggrid{
            height: 1416px;
        }
        .card-sellBuy{
            height: 1520px;
        }
        .card-twl{
            height: 1042px;
        }
        .card-tradeAgW{
            height: 1416px;
        }
        .card-autSend{
            height: 1416px;
        }
        .card-autOrd{
            height: 1042px;
        }
    }
    @media(min-height: 1973px) and (max-height: 2047px){
        .card-mc{
            height: 1595px;
        }
        .table-market>thead>tr>th{
            padding: 75px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 74px 5px !important;
        }
        .card-sib{
            height: 1250px;
        }
        .card-bwt{
            height: 1328px;
        }
        .card-watchlistAggrid{
            height: 1790px;
        }
        .card-sellBuy{
            height: 1920px;
        }
        .card-twl{
            height: 1330px;
        }
        .card-tradeAgW{
            height: 1800px;
        }
        .card-autSend{
            height: 1800px;
        }
        .card-autOrd{
            height: 1330px;
        }
    }
    @media(min-height: 2048px){
        .card-mc{
            height: 1675px;
        }
        .table-market>thead>tr>th{
            padding: 79px 5px !important;
        }
        .table-market>tbody>tr>td{
            padding: 78px 5px !important;
        }
        .card-sib{
            height: 1305px;
        }
        .card-bwt{
            height: 1379px;
        }
        .card-watchlistAggrid{
            height: 1860px;
        }
        .card-sellBuy{
            height: 2040px;
        }
        .card-twl{
            height: 1385px;
        }
        .card-tradeAgW{
            height: 1870px;
        }
        .card-autSend{
            height: 1870px;
        }
        .card-autOrd{
            height: 1385px;
        }
    }
}
.block{
    display: block !important;
}
#loader {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-right: 2px solid #0071BC;
    border-left: 2px solid #0071BC;
    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;
    width: 30px;
    margin: auto;
    display: none;
    height: 30px;
    -webkit-animation: spin 1.2s linear infinite;
    animation: spin 1.2s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@media (max-width: 1300px){
    .f-livetrade{
        font-size: 13px;
    }
}
@media (max-width: 1200px){
    .f-livetrade{
        font-size: 11px;
    }
}

@media (max-width: 1000px){
    .f-livetrade{
        font-size: 10px;
    }
}

.runningTextMac > .slidee{
    display: inline-block;
    width: 14.285%;
    height: 32px;
    overflow: hidden;
    background: var(--warna-black-white);
}
.slidee > div{
    color: #fff !important;
    height: 30px;
    margin-top: 2px;
    margin-left: 1px;
    margin-right: 1px;

    padding: 8px 2px 0px 2px;
    /*font-size: 11px;*/
    font-weight: bold;
    align-content: center;
    text-align: center;
    border-radius: 2px;

}
@media (max-width: 1300px){
    .slidee > div{
        font-size: 9px;
    }
}
.slidee > .active{
    top: -32px;
}
.slideDua > .hid-box{
    transition-delay: .3s;
}
.slideTiga > .hid-box{
    transition-delay: .5s;
}
.slideEmpat > .hid-box{
    transition-delay: .7s;
}
.slideLima > .hid-box{
    transition-delay: .9s;
}
.slideEnam > .hid-box{
    transition-delay: 1.1s;
}
.slideTujuh > .hid-box{
    transition-delay: 1.3s;
}
.slideDelapan > .hid-box{
    transition-delay: 1.5s;
}
.alert {
    color: white;
    border-radius: 10px;
    position: absolute;
    top: 8vh;
    right: 10px;
    padding: 13px;
    display: flex;
    align-items: center;
    z-index: 1111;
    -webkit-box-shadow: 3px 3px 4px -2px rgba(0,0,0,1);
    -moz-box-shadow: 3px 3px 4px -2px rgba(0,0,0,1);
    box-shadow: 3px 3px 4px -2px rgba(0,0,0,1);
}
.alert p {
    margin: 0;
}
.alert-error {
    background-image: linear-gradient(#ED1D24, #78232A);
}
.alert-success {
    background-image: linear-gradient(#8CC640,#119848);
}
.alert-info {
    background-image: linear-gradient(#0071BC,#122b40);
}
.close {
    color: white;
    cursor: pointer;
    margin-left: 10px;
    font-size: 10px;
}
.hide-this{
    display:none;
}
.btn-sa{
    margin-right: 8px; border-color: rgb(212, 63, 58);
    sbox-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgba(65, 24, 21, 0.36) 0px 0px 8px;
    font-size: 12px;
}
.btn-trans{
    color: #0071bc;
    background: transparent !important;
    border:none!important;
}
.btn-trans:hover, .btn-trans:active, .btn-trans:focus{
    text-decoration: underline;
    color: #23527c;
}
.card-addgroup-modify{
    height: 215px;
}
.tbodyGroupAdd{
    height: var(--card-240) !important;
}
.dot-login:before {
    font:bold 1.1em/0 monospace;
    content:"\2022";
    margin-left: 6px;
    margin-right: 6px;
}
.input-right>input{
    text-align: right !important;
}
.td-select-historical{
    width: 120px;
}
[col-id~="orderer"]{
    text-decoration: underline;
    font-style: italic;
}
[col-id~="updown"]{
    border-right: none !important;
    border-left: none !important;
}
.card-345{
    height: 345px;
}
.card-watchlistcust{
    height: 485px;
}
.btn-page.active,.btn-page.active:hover{
    background-image: linear-gradient(#67AAD7,#0071BC) !important;
    color: white !important;
}
.btn-page,.btn-page:active{
    background-image: linear-gradient(var(--warna-black-white-gradient),var(--warna-black-white));
    color: white !important;
}
.btn-page:active,.btn-page:hover{
    background-image: linear-gradient(var(--warna-black-white-gradient),var(--warna-black-white));
    color: #67AAD7 !important;
}
.card-stock-trade-sum{
    height: 250px;
}
.card-stock-trade-sum>tbody>tr:nth-of-type(even){
    background-color: var(--warna-hover-menu) !important;
}
.card-392-historical{
    height: 379px;
}
.lastColor{
    font-size: 20px;
    padding:0 !important;
}
.box-selection{
    font-size: 16px;
}
.card-487-pe{
    height: 400px;
}
.no-button-plusminus>span{
    display:none;
}
.btn-refresh-right{
    bottom: 20px;
    right: 20px;
}
.btn-refresh-2-right{
    bottom: -16px;
    right: -5px;
}
.d-border-bottom-grey > thead > tr > th{
    border-bottom: 1px solid grey;
}
.body-trade-sum-tb{
    height: 340px !important;
}
.btn-10{
    font-size: 10px !important;
    margin-top: -9px;
    margin-right: 2px;
}
.f-15{
    font-size: 15px;
}
.card-440-a{
    height: 445px;
}
.card-220-a{
    height: 150px;
}
.card-220-b{
    height: 168px;
}
.table-total-stock{
    margin-top: -1px;
    margin-left: -1px !important;
    border-left: 1px solid var(--warna-d-border);
    border-right: 1px solid var(--warna-d-border);
}
.table-total-stock thead tr th {
    background-color: var(--warna-bg-dark-grey);
    color: var(--warna-cssmenu);
    font-weight: bold;
    border-right: 1px solid var(--warna-d-border);
    border-bottom: 1px solid var(--warna-d-border);
}
.card-tradePL{
    height: 360px;
}
.card-tradeAdv{
    height: 343px;
}
.bold{
    font-weight: bold;
}
.rounded{
    border-radius: 10px;
}
.nameBorder{
    border: var(--warna-border-headermenu) solid 1px;
}
.card-bar{
    height: 450px;
}
.card-406{
    height: 406px;
}
body{
    /*overflow-y: hidden;*/
}

.card-310-odlist{
    height: 270px;
}
.custom-tooltip {
    position: absolute;
    min-width: 500px;
    width: 30%;
    height: 30px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,1);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,1);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 12px;
    overflow: hidden;
    pointer-events: none;
    transition: opacity .5s;
}
.custom-tooltip span{
    padding-left: 4px;
}

.custom-tooltip.ag-tooltip-hiding {
    opacity: 0;
}

.custom-tooltip p {
    margin: 5px;
    white-space: nowrap;
}

.custom-tooltip p:first-of-type {
    font-weight: bold;
}
.btn-custom-nav{
    height: 25px;
    width: 25px;
    background-image: linear-gradient(#C3C3C3, #5B5B5C) !important;
    color: white;
    padding: 0;
    margin-left: 1px;
}
.btn-custom-nav:enabled:active{
    background-image: linear-gradient(#5B5B5C, #C3C3C3) !important;
}
.btn-custom-primary{
    height: 25px;
    width: 25px;
    padding: 0;
    margin-left: 1px;
    color: white !important;
    background-image: linear-gradient(#0071BC,#122b40)
}
@media (max-width: 1024px){
    .btn-custom-nav{
        margin-top: 3px;
        font-size: 8px !important;
        height: 16px;
        width: 16px;
        background-image: linear-gradient(#C3C3C3, #5B5B5C) !important;
        color: white;
        padding: 0;
    }
    .btn-custom-nav:enabled:active{
        background-image: linear-gradient(#5B5B5C, #C3C3C3) !important;
    }
    .btn-custom-primary{
        margin-top: 3px;
        font-size: 8px !important;
        height: 16px;
        width: 16px;
        padding: 0;
        color: white !important;
        background-image: linear-gradient(#0071BC,#122b40)
    }
}
@media (min-width: 1225px) and (max-width: 1409px) {
    .btn-custom-nav{
        margin-top: 2px;
        font-size: 10px !important;
        height: 22px;
        width: 22px;
        background-image: linear-gradient(#C3C3C3, #5B5B5C) !important;
        color: white;
        padding: 0;
        margin-left: 1px;
    }
    .btn-custom-nav:enabled:active{
        background-image: linear-gradient(#5B5B5C, #C3C3C3) !important;
    }
    .btn-custom-primary{
        margin-top: 2px;
        font-size: 10px !important;
        height: 22px;
        width: 22px;
        padding: 0;
        margin-left: 1px;
        color: white !important;
        background-image: linear-gradient(#0071BC,#122b40)
    }
}
@media(min-width: 1410px) and (max-width: 1600px){
    .btn-custom-nav{
        margin-top: 4px;
        font-size: 12px !important;
        height: 24px;
        width: 24px;
        background-image: linear-gradient(#C3C3C3, #5B5B5C) !important;
        color: white;
        padding: 0;
        margin-left: 1px;
    }
    .btn-custom-nav:enabled:active{
        background-image: linear-gradient(#5B5B5C, #C3C3C3) !important;
    }
    .btn-custom-primary{
        margin-top: 4px;
        font-size: 12px !important;
        height: 24px;
        width: 24px;
        padding: 0;
        margin-left: 1px;
        color: white !important;
        background-image: linear-gradient(#0071BC,#122b40)
    }
}
@media (min-width: 1601px) and (max-width: 1700px) {
    .btn-custom-nav{
        margin-top: 3px;
        font-size: 15px !important;
        height: 30px;
        width: 30px;
        background-image: linear-gradient(#C3C3C3, #5B5B5C) !important;
        color: white;
        padding: 0;
        margin-left: 1px;
        font-size: 15px;
    }
    .btn-custom-nav:enabled:active{
        background-image: linear-gradient(#5B5B5C, #C3C3C3) !important;
    }
    .btn-custom-primary{
        margin-top: 3px;
        font-size: 15px !important;
        height: 30px;
        width: 30px;
        padding: 0;
        margin-left: 1px;
        color: white !important;
        background-image: linear-gradient(#0071BC,#122b40)
    }
}
@media (min-width: 1701px) and (max-width: 1800px) {
    .btn-custom-nav{
        margin-top: 3px;
        height: 25px;
        width: 25px;
        background-image: linear-gradient(#C3C3C3, #5B5B5C) !important;
        color: white;
        padding: 0;
        margin-left: 1px;
    }
    .btn-custom-nav:enabled:active{
        background-image: linear-gradient(#5B5B5C, #C3C3C3) !important;
    }
    .btn-custom-primary{
        margin-top: 3px;
        height: 25px;
        width: 25px;
        padding: 0;
        margin-left: 1px;
        color: white !important;
        background-image: linear-gradient(#0071BC,#122b40)
    }
}
@media (min-width: 1801px) and (max-width: 1900px){
    .btn-custom-nav{
        margin-top: 2px;
        font-size: 15px !important;
        height: 29px;
        width: 29px;
        background-image: linear-gradient(#C3C3C3, #5B5B5C) !important;
        color: white;
        padding: 0;
        margin-left: 1px;
    }
    .btn-custom-nav:enabled:active{
        background-image: linear-gradient(#5B5B5C, #C3C3C3) !important;
    }
    .btn-custom-primary{
        margin-top: 2px;
        font-size: 15px !important;
        height: 29px;
        width: 29px;
        padding: 0;
        margin-left: 1px;
        color: white !important;
        background-image: linear-gradient(#0071BC,#122b40)
    }
}
@media (min-width: 1901px){
    .btn-custom-nav{
        margin-top: 2px;
        margin-left: 2px;
        margin-right: 2px;
        font-size: 15px !important;
        height: 29px;
        width: 29px;
        background-image: linear-gradient(#C3C3C3, #5B5B5C) !important;
        color: white;
        padding: 0;
    }
    .btn-custom-nav:enabled:active{
        background-image: linear-gradient(#5B5B5C, #C3C3C3) !important;
    }
    .btn-custom-primary{
        margin-top: 2px;
        font-size: 15px !important;
        height: 29px;
        width: 29px;
        padding: 0;
        margin-left: 1px;
        color: white !important;
        background-image: linear-gradient(#0071BC,#122b40)
    }
}


.no-border-radius{
    border-radius: 0px !important;
}
.btnDatePick{
    min-height: 32px;
}
.card-344>#container>div>svg{
    transition-delay: 1s;
}
.bg-gradi{
    background: rgb(138,221,109);
    background: linear-gradient(90deg, rgba(138,221,109,1) 0%, rgba(107,138,115,1) 58%, rgba(81,66,147,1) 100%);
}
.bg-gradi2{
    background-image: url("img/bggradi2.jpg");
}
/*untuk modal migrasi*/
.b-title{
    font-weight: bold;
    color: black;
}
.b-title-sm{
    font-weight: bold;
    color: black !important;
    font-size: 15px;
}
.d-border-bottom-bold-migrate {
    border-bottom: white solid 2px!important;
}
.p-migrate{
    color: black;
    font-size: 12px;
}
.card-448>tr>td{
    padding-top: 1.11vh;
}
.text-black{
    color: black !important;
}
.text-black>label{
    color: black !important;
}
.go-button>i{
    padding-top: 6px;
    padding-left: 5px;
    font-size: 27px;
}
.go-button{
    height: 50px;
    width: 50px;
}
.imgMarketStream{
    height: 100%;
    width: 100%;
    opacity: 0.6;
}
.box-selection-login {
        max-width: 100% !important;
}
.even-td-blue{
    background-color: rgba(0, 56, 255, 0.1);
}
table .tb-align-center>tr>td{
    vertical-align: center !important;
}
.card-444{
    height: 444px;
}
.logo-trial{
    width: 75px;
    height: 75px;
    background: #6a7788;
    border-radius: 10%;
}
.ui.modal.large2>.content,.ui.modal.large2>.header{
    background: white !important;
}
.bg-content-trial{
    /*background: #EBEEF3 !important;*/
    padding: 23px 35px 13px 35px !important;
}
/*div border bottom*/
.dbb{
    background: #6a7788;
    -webkit-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.63);
    -moz-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.63);
    box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.63);
    height: 3px;
    margin: 8px 15px 10px 15px;
    width: 100%;
}
.ui.large2>.header{
    display: none;
}
.bb-blue{
    border-bottom: 2px solid darkblue;
}
.bg-currencies{
    background: #232730;
}
.col-mn-7>a{
    padding: 15px 5px!important;
}
.card-354{
    width: 100%;
    height: 354px;
}
.mx-width{
    max-width: 200px;
}
.btn-live-update{
    display: inline-block;
    padding: 7px 7px;
    margin-bottom: 0;
    font-size: 10px;
    font-weight: normal;
    line-height: 1.2;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
.td-gradient-yellow{
    background: rgb(255,212,68);
    background: linear-gradient(90deg, rgba(255,212,68,1) 0%, rgba(231,121,12,1) 84%);
}
.card-Aut-Order{
    width: 100%;
    height: 209px;
}
.f-sdb{
    font-size:10px;
}
@media(min-width: 1279px) and (max-width: 1359px){
    .f-sdb{
        font-size:9px;
    }
    .f-sdb-m{
        font-size:11px;
    }
}
@media(min-width: 1360px) and (max-width: 1440px) {
    .f-sdb{
        font-size: 9px;
    }
    .f-sdb-m{
        font-size: 10px;
    }
}
@media(min-width: 1441px) and (max-width: 1679px) {
    .f-sdb{
        font-size: 10px;
    }
    .f-sdb-m{
        font-size: 13px;
    }
}
@media(min-width: 1680px) and (max-width: 1919px){
    .f-sdb{
        font-size: 12px;
    }
    .f-sdb-m{
        font-size: 14px;
    }
}
@media(min-width: 1920px) and (max-width:  2047px){
    .f-sdb{
        font-size: 14px;
    }
    .f-sdb-m{
        font-size: 16px;
    }
}
@media(min-width: 2048px) and (max-width:  2303px){
    .f-sdb{
        font-size: 15px;
    }
    .f-sdb-m{
        font-size: 17px;
    }
}
@media(min-width: 2304px) and (max-width:  2559px){
    .f-sdb{
        font-size: 19px;
    }
    .f-sdb-m{
        font-size: 20px;
    }
}
@media(min-width: 2560px) and (max-width: 2900px){
    .f-sdb{
        font-size: 20px;
    }
    .f-sdb-m{
        font-size: 22px;
    }
}
.z99{
    z-index: 99 !important;
}
.z0{
    z-index: 0 !important;
}
.card-tradeSO{
    height: 460px;
}
.card-brokerInfo{
    height: 420px;
}
.card-brokerTradeSummary{
    height: 475px;
}
.card-240{
    height: 240px;
}
.card-intIndices{
    height: 440px;
}
.card-commodity{
    height: 450px;
}
.card-currencies{
    height: 385px;
}
.f-19{
    font-size: 19px;
}
.f-30{
    font-size: 30px;
}
@media (min-width: 1365px) AND (max-width: 1370px){
    .card-520{
        height: 524px;
    }
}
#tradeValue{
    text-align: right !important;
}
.card-tradeEO{
    height: 430px;
}
.tb-dailytech{
    height: 105px;
}
.card-topDT{
    height: 50px;
}
.text-xs{
    font-size: 6px;
}
.ti-5{
    text-indent: 5rem;
}
.tb-right-info{
    width: 135%;
    max-width: none !important;
}

.card-specialNotation {
    height: 475px;
}

.tb-notation th{
    background-color: rgb(27, 34, 44);
    color: white;
}
.tb-notation tr:nth-child(even) {
    background-color: #EBEDF0;
    color: black;
  }
.tb-notation tr:nth-child(odd) {
    background-color: #F2F4F7;
    color: black;
}
.tb-notation{
    border-collapse: collapse;
}
.tb-notation table, .tb-notation tr td{
    border: 1px solid #c6c6c6;
}
.rborder-blue{
    border-right:1px solid #0275d8;
}
.no-bg-image{
    background-image: none !important;
}
.tb-fund-performance td{
    padding-top: 0px;
    padding-bottom: 0px;
}
.card-160{
    height: 160px;
}
.card-530{
    /* height: 530px; */
    height: 580px;
}
.etalase-filter>.text{
    width: 100%;
}
.card-120{
    height: 120px;
}
.card-140{
    height: 140px;
}
.em10{
    font-size: 10em;
}
.em4{
    font-size: 4em;
}
.icon-rpa{
    width: 150px;
    margin: auto;
    position: relative;
    margin-top: 18vh;   
}
.icon-rpa>.em4{
    position: absolute;
    left:0px;
}
/* speedometer gaugae */

.gauge-wrapper {
  display: inline-block;
  width: auto;
  margin: 0 auto;
  padding: 20px 15px 15px;
}

.gauge {
  background: #e7e7e7;
  box-shadow: 0 -3px 6px 2px rgba(0, 0, 0, 0.50);
  width: 200px;
  height: 100px;
  border-radius: 100px 100px 0 0!important;
  position: relative;
  overflow: hidden;
}
.gauge.min-scaled {
  transform: scale(0.5);
}

.gauge-center {
  content: '';
  color: #fff;
  width: 60%;
  height: 60%;
  background: #15222E;
  border-radius: 100px 100px 0 0!important;
  position: absolute;
  box-shadow: 0 -13px 15px -10px rgba(0, 0, 0, 0.28);
  right: 21%;
  bottom: 0;
  color: #fff;
  z-index:10;
}

.gauge-center .label, .gauge-center .number {display:block; width: 100%; text-align: center; border:0!important;}
.gauge-center .label {font-size:0.75em; opacity:0.6; margin:1.1em 0 0.3em 0;}
.gauge-center .number {font-size:1.2em;}

.needle {
  width: 80px;
  height: 7px;
  background: #15222E;
  border-bottom-left-radius: 100%!important;
  border-bottom-right-radius: 5px!important;
  border-top-left-radius: 100%!important;
  border-top-right-radius: 5px!important;
  position: absolute;
  bottom: -2px;
  left: 20px;
  transform-origin: 100% 4px;
  transform: rotate(0deg);
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.38);
  display:none;
  z-index:9;
}
.rotate-low{
    transform: rotate(22deg);
}
.rotate-lowmid{
    transform: rotate(67deg);
}
.rotate-mid{
    transform: rotate(112deg);
}
.rotate-high{
    transform: rotate(157deg);
}
.four.rischio1 .needle {animation: fourspeed1 2s 1 both; animation-delay: 1s; display:block;}
.four.rischio2 .needle {animation: fourspeed2 2s 1 both; animation-delay: 1s; display:block;}
.four.rischio3 .needle {animation: fourspeed3 2s 1 both; animation-delay: 1s; display:block;}
.four.rischio4 .needle {animation: fourspeed4 2s 1 both; animation-delay: 1s; display:block;}

.slice-colors {height:100%;}

.slice-colors .st {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border: 50px solid transparent;  
}


.four .slice-colors .st.slice-item:nth-child(2) {
  border-top: 50px #f1c40f solid;
  border-right: 50px #f1c40f solid;
  background-color:#1eaa59;
}

.four .slice-colors .st.slice-item:nth-child(4) {
  left:50%;
  border-bottom: 50px #E84C3D solid;
  border-right: 50px #E84C3D solid;
  background-color:#e67e22;
}
.gauge-desc{
    margin: auto;
    width: 40%;
    padding: 30px;
}

.d-border-radius-1 {
    border-radius: 0.3em;
}
.box-question {
    font-size: 18px;
}
.card-169-mf{
    height: 169px;
}
.card-480 {
    height: 480px;
}

.info-box{
    display: block;
    min-height: 90px;
    width: 100%;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    margin-bottom: 15px;
}

.info-box-icon{
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
    display: block;
    float: left;
    height: 90px;
    width: 90px;
    text-align: center;
    font-size: 45px;
    line-height: 90px;
    background: rgba(0,0,0,0.2);
}

.info-box-content {
    padding: 10px 10px;
    margin-left: 90px;
}

.info-box-text {
    display: block;
    font-size: 21px;
    margin-bottom: 4px;
}

.info-box-text {
    text-transform: uppercase;
}

.info-box-number {
    display: block;
    font-style: italic;
    font-weight: lighter;
    font-size: 17px;
}


.answer-box{
    display: block;
    min-height: 60px;
    border: 1px solid grey;
    width: 100%;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    margin-bottom: 5px;
    background-color: grey;
    border-radius: 5px;
}

.answer-box-icon{
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
    display: block;
    float: left;
    height: 60px;
    width: 50px;
    text-align: center;
    font-size: 25px;
    line-height: 60px;
    background: rgba(0,0,0,0.2);
    text-transform: uppercase;
}

.answer-box-content {
    padding: 5px 10px;
    margin-left: 50px;
    min-height: 60px;
}

.answer-box-text {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
}

.answer-box-text {
    text-transform: uppercase;
}

.answer-box-number {
    display: block;
    font-style: italic;
    font-weight: lighter;
    font-size: 12px;
}

.answer-box-selected{
    background-color: rgb(71, 71, 71);
}

.ui.checkbox.text-bold label {
    font-weight: bold;
}

.card-430{
    height: 430px;
}