@font-face {
  font-family: 'bips-icon';
  src: url('../font/bips-icon.eot?52435916');
  src: url('../font/bips-icon.eot?52435916#iefix') format('embedded-opentype'),
       url('../font/bips-icon.woff2?52435916') format('woff2'),
       url('../font/bips-icon.woff?52435916') format('woff'),
       url('../font/bips-icon.ttf?52435916') format('truetype'),
       url('../font/bips-icon.svg?52435916#bips-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'bips-icon';
    src: url('../font/bips-icon.svg?52435916#bips-icon') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "bips-icon";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-exit-fullscreen:before { content: '\e800'; } /* '' */
.icon-icon-market-statistic:before { content: '\e801'; } /* '' */
.icon-icon-star-list:before { content: '\e802'; } /* '' */
.icon-icon-analytic_page:before { content: '\e803'; } /* '' */
.icon-icon-stock-page:before { content: '\e804'; } /* '' */
.icon-icon-investment-board:before { content: '\e805'; } /* '' */
.icon-icon-trade-page:before { content: '\e806'; } /* '' */
.icon-icon-chat-support:before { content: '\e807'; } /* '' */
.icon-icon-live-trade:before { content: '\e808'; } /* '' */
.icon-icon-lock-login:before { content: '\e809'; } /* '' */
.icon-icon-user-login:before { content: '\e80a'; } /* '' */
.icon-icon-buy-btn:before { content: '\e80b'; } /* '' */
.icon-icon-fund:before { content: '\e80c'; } /* '' */
.icon-icon-fullscreen-in:before { content: '\e80d'; } /* '' */
.icon-icon-sell-btn:before { content: '\e80e'; } /* '' */
.icon-icon-amend-btn:before { content: '\e80f'; } /* '' */
.icon-icon-historical:before { content: '\e810'; } /* '' */
.icon-icon-inquiry:before { content: '\e811'; } /* '' */
.icon-icon-portofolio:before { content: '\e812'; } /* '' */
.icon-icon-transactional:before { content: '\e813'; } /* '' */
.icon-icon-change-pinpass:before { content: '\e814'; } /* '' */
.icon-icon-stock-list:before { content: '\e815'; } /* '' */